<template lang="pug">
div.terms-view
    h1 Conditions générales
    img(src="../../assets/Img/Leaf.png", alt="Leaf icon")
    terms
    br
    br
    br

</template>

<script>
import Terms from './../../components/legal/TermsAndConditions'

export default {
      components: {
        Terms
    },
}
</script>

<style lang="scss" scoped>
.terms-view {
    padding: 20px 40px;
    min-height: calc(100vh - 270px);
    background: var(--black);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-image: url('./../../assets/Img/slider.png');
    background-repeat:  no-repeat;
    background-position-x: 75%;
    h1 {
        font-size: var(--tbig);
        margin-top: 40px;
    }
    img {
        width: 120px;
        margin: 45px 0;
    }
}
</style>