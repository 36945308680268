import { Firestore } from './../firebase/Firestore'

export default {

    namespaced: true,

    state: {

        meetings: {}

    },
    mutations: {

        setMeetings(state, meetings) {
            state.meetings = {...meetings}
        },

        removeMeeting(state, meetingId) {
            if(state.meetings[meetingId]) {
                delete state.meetings[meetingId]
            }
        },

    },
    actions: {

        fetchMeetings({ commit }, providerId) {
            Firestore.collection('providers').doc(providerId).collection('meetings').get()
                .then(snap => {
                    let datas = {}
                    snap.forEach(d => {
                        datas[d.id] = d.data()
                    })
                    commit('setMeetings', datas)
                })
        },

        updateMeeting({ dispatch }, payload) {
            Firestore.collection('providers').doc(payload.providerId).collection('meetings').doc(payload.meetingId).set(payload.body, {merge: true})
            dispatch('fetchMeetings', payload.providerId)
        },

        deleteMeeting({ commit }, payload) {
            Firestore.collection('providers').doc(payload.providerId).collection('meetings').doc(payload.meetingId).delete()
            commit('removeMeeting', payload.meetingId)
        },

        createMeeting({ dispatch }, payload) {
            Firestore.collection('providers').doc(payload.providerId).collection('meetings').add(payload.data)
            .then(() => {
                dispatch('fetchMeetings', payload.providerId)
            })
        }

    },
    getters: {

        meetings: state => state.meetings,

        dailyMeetings: state => date => {
            let toReturn = {}

            Object.keys(state.meetings).forEach(k => {
                let meeting = state.meetings[k]
                if(meeting.date == date) {
                    toReturn[k] = meeting
                }
            })

            return toReturn
        }

    }

}