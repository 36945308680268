<template lang="pug">
div.terms-container
    h3 CONDITIONS GENERALES DE VENTE ET D'UTILISATION DE LA PLATEFORME INTERNET 
    p comparateurpompesfunebres.fr / comparateur-pompes-funebres.fr / comparateur-pompes-funebres.com / pompes-funebres-compare.fr / pompes-funebres-compare.com, désignée ci-après «la plateforme» ou «le site»
    br 
    p Ci-après seront dénommé(e)s « le prestataire » votre société, et « l'intermédiaire » la société ADK Cocorocchia, entreprise individuelle ayant pour numéro de SIRET CHE-197.238.439 et qui est domiciliée à l’adresse suivante : Avenue du casino 17, Montreux 1820
    br
    h3 1. CONDITIONS D'ADHESION A LA PLATEFORME
    p Étant préalablement rappelé que : l’intermédiaire réalise et gère le site Internet dont les adresses sont comparateurpompesfunebres.fr / comparateur-pompes-funebres.fr / comparateur-pompes-funebres.com / pompes-funebres-compare.fr / pompes-funebres-compare.com et dont le but est de mettre en relation des entreprises de pompes funèbres avec des particuliers, qui sont de potentiels clients (ci-après dénommés « clients »).
    br
    p Principe de présence sur la plateforme : 
    p La plateforme est une place de marché pour toute personne souhaitant entrer en contact avec une entreprise de pompes funèbres (ci-après dénommé « le prestataire ») pour réaliser des obsèques. Les Clients, après avoir sélectionné via le comparateur le prestataire entreront leurs coordonnées pour être contactés
    br
    h3 2. CONDITIONS GENERALES DE VENTE
    strong Article 1 - Objet du contrat
    br
    p Le présent contrat a pour objet de définir les conditions de communication, par le prestaire, qui s’engage à appeler le client le plus rapidement possible. Le prestataire reconnaît que cette communication et mise en relation conséquentes n'ont été possibles que grâce à l’intermédiaire. Par les présentes, l'intermédiaire s'engage à fournir au prestataire les coordonnées complètes du client (nom, numéro de téléphone, email), par tout moyen convenu entre eux (interface de la plateforme). 
    br
    strong Article 2 - Modalités de référencement 
    p Le prestataire n’accédera aux coordonnées des clients qu'après que celui-ci a renseigné le profil de sa société sur les sites comparateurpompesfunebres.fr / comparateur-pompes-funebres.fr / comparateur-pompes-funebres.com / pompes-funebres-compare.fr / pompes-funebres-compare.com. Ce qui inclut : son logo / visuel d'identification, photos, le prix moyen d’une prestation d’obsèques, devant impérativement refléter la réalité, ainsi qu’un remplissage suivi et précis de son agenda. De même l'intermédiaire n'assure au prestataire aucune exclusivité : les clients peuvent chercher des prestataires par d'autres moyens que le site comparateur-constructeur.com ainsi que sélectionner plusieurs prestataires via la plateforme
    br
    strong Article 3 – Rémunération, modalités de paiement et reconductions
    br
    p L'accès à la plateforme est soumis à la souscription d’un abonnement mensuel de 39.90€ par mois. 
    br
    p La mise en relation entre le client et le prestataire sera facturé 10€ au prestataire
    br
    p Modalité de paiement :
    br
    p Par carte bleue via la plateforme sécurisée Stripe. 
    br 
    p Reconduction : 
    p Il s'agit d'un abonnement mensuel avec tacite reconduction. L'intermédiaire a recours aux services de Stripe pouvant débiter chaque mois le même montant initialement perçu, sur la carte bleue du client. Le client peut interrompre à tout moment son abonnement (voir clause résiliation) la société ADK Cocorocchia se réserve le droit de modifier à tout moment ses conditions générales de vente. 
    br
    strong Article 4 - Exonération de responsabilité de l'intermédiaire
    p L'intermédiaire s'engage à transmettre les coordonnées du client dès lors que ce dernier aura réglé le montant de la mise en relation. L'intermédiaire ne garantit pas la conclusion et la signature du contrat entre le prestataire et le client. Son rôle se borne à la mise en relation. En tout état de cause cette conclusion et signature d’un contrat reste de la seule et entière responsabilité du prestataire. De même, la responsabilité de l'intermédiaire ne saurait être engagée en cas de fourniture erronée d'informations concernant le client pas plus qu'en cas de retrait de sa part, celui-ci restant libre de contracter ou non. Le prestataire est pleinement responsable des informations qu'il publie sur le site de l'intermédiaire. En cas de fourniture d'informations erronées par le prestataire concernant sa société et les produits et services qu'elle propose ainsi que le tarifs moyen indiqué, cela sera de nature à constituer une publicité mensongère, la responsabilité de l'intermédiaire ne saurait être engagée. La responsabilité de la société ADK Cocorocchia ne saurait être engagée en cas de propos diffamatoires, d'ordre racistes et injurieux ou dans le cadre d'utilisation de contenus de type photographiques, textuels ou vidéos soumis à droits d'auteur par le prestaire. Le prestataire garantit que la responsabilité de l'intermédiaire ne pourra en aucun cas être mise en cause en raison de demandes qu'il subirait ou de réclamations formulées contre lui et émanant d'un tiers quel qu'il soit, notamment d'un client. 
    br
    strong Article 5 - Engagements du prestataire 
    p Le prestataire s'engage à garantir la validité des informations fournies dans le formulaire d'inscription (nom, raison social, numéro RCS ou RCM, adresse, téléphone, ...). Le prestataire garantit être en droit de proposer ou de distribuer les produits et/ou services proposés aux clients, être à jour de ses assurances Responsabilité Civile Professionnelle. Le prestataire s'engage à proposer des devis qui ne sont ni trompeurs, ni incorrects pour lesquels il est capable de mener à bien l'exécution. Concernant sa fiche entreprise le prestataire reconnaît être responsable du contenu textuels et graphiques ainsi que des éventuels droits, licences images ou autres qu'elles pourraient nécessiter. Le prestaire s'engage à répondre à toutes les demandes de contact des clients. Il s'engage en outre à n'afficher aucune coordonnée (téléphonique, internet, email ou adresse) de manière visible des clients et utilisateurs de la plateforme sur sa fiche. Dans un tel cas, la société ADK Cocorocchia se réserve le droit de supprimer l'information. En cas d'infraction répétée à ces engagements, la société ADK Cocorocchia jugera qu'il s'agit d'un abus du service et pourra interrompre la présence en ligne du client sans dédommagement.
    br
    strong Article 6 - Confidentialité 
    p Chacune des parties au présent contrat s'engage en son nom comme en celui de ses collaborateurs à considérer comme confidentiels, pendant la durée du présent contrat et après son expiration, les documents, accords financiers, remises exceptionnelles, savoir-faire en provenance de l'autre partie dont elle pourrait avoir eu connaissance à l'occasion de l'exécution du présent contrat, et à ne pas les utiliser en dehors des besoins du présent accord. 
    br
    strong Article 7 - Durée du contrat & modalités de résiliation 
    p L'adhésion aux sites: comparateurpompesfunebres.fr / comparateur-pompes-funebres.fr / comparateur-pompes-funebres.com / pompes-funebres-compare.fr / pompes-funebres-compare.com est enregistrée en jours ouvrables. La durée de l'adhésion mensuelle et résiliable à tout moment sans frais de résiliation. Le prestaire a la capacité de résilier le présent contrat mail à l’adresse suivante: comparateurpompesfunebres@gmail.com. En poursuivant son inscription sur le site, prestaire s'engage à respecter les clauses du contrat contracté entre lui et la société ADK Cocorocchia, à en respecter l'esprit et la lettre, particulièrement : 
    ul
        li Les obligations et déclarations du prestataire 
        li Exonération de responsabilité de l'intermédiaire 
        li Clause de non-concurrence 
    br
    p La société ADK Cocorocchia se réserve le droit de résilier, sans mise en demeure préalable, tout forfait conclu avec un prestataire en cas de non-respect par ce dernier de l'une des dispositions du contrat. Les sommes échues resteront dues et il ne sera procédé à aucun remboursement. 
    br
    strong Article 8 - Clause d'indivisibilité 
    p Toutes les clauses du présent contrat sont de rigueur, aucune d'entre elles ne peut être réputée de style et chacune est une condition déterminante de la convention sans laquelle les parties n'auraient pas contracté. 
    br 
    stong Article 9 - Droit de propriété intellectuelle et autorisation de publication
    p La société ADK Cocorocchia est titulaire de l'intégralité des droits de propriété intellectuelle liés au site. Les utilisateurs du site s'engagent à ne pas utiliser, exploiter ou détourner tout ou partie des éléments composant le site. Concernant les visuels et textes des prestataires proposés par eux pour être mis en ligne, le prestataire autorise la société ADK Cocorocchia à les diffuser sur la plateforme, sans restriction particulière. Il ne pourra être reproché à la société ADK Cocorocchia la présence de ces éléments en ligne. Néanmoins, le prestataire peut exiger de ne plus apparaître en ligne.
    br 
    strong Article 10 - Protection des données Les données relatives aux utilisateurs du service sont destinées à un usage interne la plateforme. 
    p Elles peuvent aussi être communiquées, avec votre accord. 
    br 
    strong Article 11 - Modifications des conditions générales et du site 
    p La société ADK Cocorocchia peut modifier à tout moment les conditions générales notamment pour se conformer à une disposition légale. Les utilisateurs seront informés de manière adéquate des modifications des conditions générales. La société ADK Cocorocchia a la possibilité de modifier tout ou partie du site, dans son graphisme, son contenu ou son organisation. Les modifications seront notifiées de manière adéquate à l'ensemble des prestataires.
</template>

<script lang="ts">

export default {

    data() {
        return {
            exist: false
        }
    }

}
</script>

<style lang="scss" scoped>
.terms-container {

}
</style>