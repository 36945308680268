import { Firestore } from './../firebase/Firestore'
import { Functions } from './../firebase/Functions'

let collectionName = 'admins'

export default {

    namespaced: true,

    state() {
        return {
            isAdmin: false,
            unverifiedProviders: {}
        }
    },
    mutations: {
        isAdmin(state, isAdmin) {
            state.isAdmin = isAdmin
        },
        addUnverifiedProvider(state, payload) {
            state.unverifiedProviders = {
                ...state.unverifiedProviders,
                [payload.id]: payload.data
            }
        },
        removeUnverifiedProvider(state, id) {
            delete state.unverifiedProviders[id]
        }
    },
    actions: {
        fetchAdminPolicies({ commit }, id) {
            Firestore.collection(collectionName)
                .doc(id)
                .get()
                .then(doc => {
                    if(doc.exists) {
                        commit('isAdmin', doc.data().is_admin !== undefined ? doc.data().is_admin : false)
                    } else {
                        commit('isAdmin', false)
                    }
                })
        },

        getUnverifiedProfiles({ commit }) {
            return new Promise((resolve, reject) => {
                Firestore.collection('providers')
                .where('isVerified', '==', false)
                .get()
                .then(querySnapshot => {
                    querySnapshot.forEach((doc) => {

                        Functions.httpsCallable('getProviderProfile')({
                            providerId: doc.id
                        }).then(result => {
                            if(result.data) {
                                commit('addUnverifiedProvider', {
                                    id: doc.id,
                                    data: result.data
                                })
                            }
                        }).catch(err => {
                            console.error(err)
                        })

                        
                    })
                    resolve(true)
                })
                .catch(err => {
                    reject(err)
                })
            })
        },

        validateAccount({ dispatch, commit }, providerId) {
            Functions.httpsCallable('validateProvider')({
                providerId: providerId
            }).then(result => {
                if(result) {
                    commit('removeUnverifiedProvider', providerId)
                }
                dispatch('getUnverifiedProfiles')
            })
        }
    },
    getters: {
        isAdmin: state => state.isAdmin,
        unverifiedProviders: state => state.unverifiedProviders
    } 

}