import { Auth } from './../firebase/Auth'

export default {

    namespaced: true,

    state() {
        return {
            user: null
        }
    },

    mutations: {
        accountCreated(state, userInfo) {
            state.user = userInfo
        },

        userLogin(state, userInfo) {
            state.user = userInfo
        },

        userLogout(state) {
            state.user = null
        }
    },

    actions: {

        createAccount({ commit }, credentials) {

            let email = credentials.email
            let password = credentials.password

            return new Promise((resolve, reject) => {
                Auth.createUserWithEmailAndPassword(email, password)
                .then(UserCredential => {
                    commit('accountCreated', UserCredential.user)
                    resolve(UserCredential)
                })
                .catch(err => {
                    console.warn(err)
                    reject(err)
                })

            })
            
        },

        login({ commit, dispatch }, credentials) {
            
            let email = credentials.email
            let password = credentials.password

            return new Promise((resolve, reject) => {
                Auth.signInWithEmailAndPassword(email, password)
                    .then(UserCredential => {
                        commit('userLogin', UserCredential.user)
                        dispatch('fetchAdminPolicies', UserCredential.user.uid, {root: true})
                        resolve(UserCredential)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        logout({ commit }) {
            return new Promise((resolve, reject) => {
                Auth.signOut()
                    .then(() => {
                        commit('userLogout')
                        resolve(true)
                    })
                    .catch(() => {
                        reject(false)
                    })
                    
            })
        }

    },

    getters: {
        user: state => state.user,
        isLoggedIn: state => state.user !== null
    }

}