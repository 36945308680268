<template lang="pug">
div.profile-container
    div.menu-container
        template(v-if="isVerified == false")
            div.menu-content
                p(@click="$router.push('/providers/profile/check-account'), selected = 0" :class="{highlight:selected == 0}") Verification du compte
                p(@click="logout()" :class="{highlight:selected == 4}") Déconnexion
        template(v-else)
            div.menu-content
                div
                    p(@click="$router.push('/providers/profile/description'), selected = 1" :class="{highlight:selected == 1}") Ma fiche descriptive
                    p(@click="$router.push('/providers/profile/calendar'), selected = 2" :class="{highlight:selected == 2}") Calendrier
                    p(@click="$router.push('/providers/profile/linkings'), selected = 3" :class="{highlight:selected == 3}") Mes mise en relation
                div
                    p(@click="logout()" :class="{highlight:selected == 4}") Déconnexion
    div.main-container
        RouterView
</template>

<script>

export default {

    data() {
        return {
            selected: 1,
        }
    },
    computed: {
        user() {
            return this.$store.getters['auth/user']
        },

        isVerified() {
            let provider = this.$store.getters['provider/provider']
            if (!provider) {
                return false
            }

            return provider.isVerified
        },

        isAdmin() {
            return this.$store.getters['admin/isAdmin']
        }
    },

    methods: {

        logout() {
            this.$store.dispatch('auth/logout')
                .then(() => {
                    this.$router.push('/providers/login')
                })
        }

    },

    watch: {
        isVerified(val) {
            if(val === true) {
                this.$router.push('/providers/profile/description')
            } else if(val === false) {
                this.$router.push('/providers/profile/check-account')
            }
        },
        isAdmin(val) {
            if(val === true) {
                this.$router.push('/admin')
            }
        }
    },

    mounted() {
        this.$store.dispatch('admin/fetchAdminPolicies', this.$store.getters['auth/user'].uid)
        this.$store.dispatch('provider/fetchProvider', this.$store.getters['auth/user'].uid)
    }


}
</script>

<style lang="scss" scoped>
.profile-container {
    height: calc(100vh - 270px);
    background-image: url('./../../assets/Img/sliderB.png');
    background-repeat: no-repeat;
    background-position-x: 75%;
    .menu-container {
        position: fixed;
        top: 70px;
        left: 0px;
        height: calc(100vh - 300px);
        width: 250px;
        background-color: var(--greyl);
        padding-top: 30px;
        .menu-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        p {
            padding: 10px;
            cursor: pointer;
            color: var(--greyd);
            transition: .25s;
            &:hover {
                color: var(--dark);
                transition: .25s;
            }
            &.highlight {
                position: relative;
                color: var(--dark);
                transition: .25s;
                &:after {
                    content: '';
                    position: absolute;
                    height: 20px;
                    width: 4px;
                    right: 0px;
                    background: var(--green);
                }
            }
        }
    }
    .main-container {
        width: calc(100% - 300px);
        height: calc(100% - 60px);
        padding-left: 300px;
        padding-top: 60px;
        overflow: scroll;
    }
}
</style>