<template lang="pug">
div.login-container
    div.card
        h1 Se connecter
        div.field
            input(type="email" name="email" id="email" placeholder=" " v-model="email")
            label(for="email") Adresse e-mail
        div.field
            input(type="password" name="password" id="password" placeholder=" " v-model="password")
            label(for="password") Mot de passe
        p.wrong(v-if="wrong") E-mail ou mot de passe invalide
        button.btn.btn-validate(@click="login()") Connexion
</template>

<script>
export default {

    data() {
        return {
            email: '',
            password: '',
            wrong: false
        }
    },

    methods: {

        login() {
            this.$store.dispatch('auth/login', {email: this.email, password: this.password})
                .then(() => {
                    this.$router.push('/providers/profile')
                }, ()=> {
                    this.wrong = true
                })
        }

    }

}
</script>

<style lang="scss" scoped>
.login-container {
    min-height: calc(100vh - 270px);
    background: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./../../assets/Img/slider.png');
    background-repeat:  no-repeat;
    background-position-x: 75%;
    .card {
        height: 400px;
        width: 400px;
        background: var(--white);
        border-radius: 10px;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
            font-size: var(--fbig);
            font-family: 'Montserrat-M';
            text-align: center;
            margin: 0 0 30px 0;
        }
        .field {
            width: 300px;
        }
        button {
            margin: 30px 0 0 0;
        }
        .wrong {
            text-transform: uppercase;
            letter-spacing: 0.05em;
            font-size: 10px;
            color: var(--red);
            animation: lappear .15s ease-out forwards;
        }
    }
}
</style>