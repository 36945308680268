<template lang="pug">
div.calendar-container
    div.left-container
        p Agenda
        div.calendar
            div.header
                div.arrow-cont(v-on:click='prevMonth()')
                    img.arrow(src="./../../assets/Icons/left-arrow.png" alt="Arrow icon")
                p {{moment(currentMonth+1,'MM').format('MMMM') }} 
                    span {{currentYear}}
                div.arrow-cont(v-on:click='nextMonth()')
                    img.arrow-r(src="./../../assets/Icons/left-arrow.png" alt="Arrow icon")
            div.week(v-for='week in calendar')
                div.day(v-for='day in week.days' v-on:click='selectDay(day)' v-bind:class='[{in : isSameMonth(day.date)}, \
                {active : day.date == activeDay}]')
                    div.day-name
                        | {{moment(day.date).format('ddd')}}
                    div.day-number
                        | {{moment(day.date).format('DD')}}
                    div.day-event
                        div.active-event(v-if='day.event.length')
                div.seleted-day
        div.new-event(v-if="activeDay")
            p.add-txt Ajouter un évenement le: 
                span {{ moment(activeDay).format('Do MMMM YYYY') }}
            div.field
                input(type="text" name="text" id="text" placeholder=" " v-model="createTitle")
                label(for="text") Titre
            div.field
                textarea(v-model="createDescription")
                p.label DESCRIPTION
            div.field
                input(type="text" name="tel" id="tel" placeholder="06........" v-model="createTel")
                label(for="tel") Numéro de telephone
            div.field
                input(type="text" name="mail" id="mail" placeholder="xxx@gmail.com" v-model="createMail")
                label(for="mail") Email
            div.btn-section
                button.btn.btn-validate(@click="submit()" ) Ajouter
                button.btn.btn-validate.disabled(@click="createTitle = ''; createDescription = ''; createMail = ''; createTel = ''") Annuler
    div.right-container
        p Evenements
        div.card(v-bind:class="{ 'active': toggle === 'active' }" @click="toggle = toggle !== 'active' ? 'active' : null" v-for="meeting in dailyMeetings")
            p.name {{ meeting.title }}
            div.desc 
                p {{ meeting.description }}
            div.info
                p Mail: {{ meeting.mail }}
                p Tel: {{ meeting.tel }}

</template>

<script>
import moment from 'moment'

moment.locale('fr')

export default {
	data() {
		return {
			createTitle: '',
			createDescription: '',
			createMail: '',
			createTel: '',

			moment: moment,
			startWeek: moment,
			activeDay: '',
			endWeek: moment,
			calendar: [],
			today: moment(),
			currentMonth: '',
			currentYear: '',
			daySelected: '',
			toggle: null
		}
	},
	methods: {

		submit() {
			this.$store.dispatch('meeting/createMeeting', {
				providerId: this.$store.getters['auth/user'].uid,
				data: {
					mail: this.createMail,
					tel: this.createTel,
					title: this.createTitle,
                    description: this.createDescription,
                    date: moment(this.activeDay).format('l')
				}
            })
            

		},

		setMounthWeek: function() {

			this.startWeek = this.today.clone().startOf('month').clone().week()
			this.endWeek = this.today.clone().endOf('month').clone().week()

			if (this.today.month() === 11) {
				this.endWeek = 53
			}
			if (this.today.month() === 0) {
				this.startWeek = 0
			}

			this.calendar = []
			for (var week = this.startWeek; week < this.endWeek + 1; week++) {
				this.calendar.push({
					week: week,
					days: this.getDay(week)
				})
			}
		},
		getDay: function(week) {
			return this.days = Array(7).fill(0).map((n, i) => {

				let day = this.today.clone().week(week).startOf('week').clone().add(n + i, 'day').clone()
				let event = this.getDayEvent(day)
				return {
					date: day,
					event: event
				}
			})
		},
		getDayEvent: function(day) {
            let counter = []
            Object.keys(this.meetings).forEach(k => {
                if(this.meetings[k].date == moment(day).format('l')) {
                    counter.push(this.meetings[k])
                }
            })
            return counter
		},
		isSameMonth: function(day) {
			return (day.month() === this.currentMonth)
		},
		nextMonth() {
			this.today.add(1, 'month')
			this.setMonth()
			this.setYear()
			this.setMounthWeek()
		},
		prevMonth() {
			this.today.subtract(1, 'month')
			this.setMonth()
			this.setYear()
			this.setMounthWeek()
		},
		setMonth: function() {
			this.currentMonth = this.today.month()
		},
		setYear: function() {
			this.currentYear = this.today.year()
		},
		selectDay: function(day) {
			this.activeDay = day.date;
			this.daySelected = day;
		}
	},

	computed: {
		meetings() {
			return this.$store.getters['meeting/meetings']
        },
        dailyMeetings() {
            if(this.activeDay) {
                return this.$store.getters['meeting/dailyMeetings'](moment(this.activeDay).format('l'))
            }
            return {}
        },

		isVerified() {
            let provider = this.$store.getters['provider/provider']
            if (!provider) {
                return false
            }

            return provider.isVerified
        },
	},

	watch: {
        isVerified(val) {
            if (val === false) {
                this.$router.push('/providers/profile/check-account')
            }
        },
    },

	mounted: function() {
		this.$store.dispatch('meeting/fetchMeetings', this.$store.getters['auth/user'].uid)

		this.setMonth()
		this.setYear()
		this.setMounthWeek()

        if(this.isVerified === false) {
            this.$router.push('/providers/profile/check-account')
        }
		// console.log(this.calendar)
	}
}
</script>

<style lang="scss" scoped>
.calendar-container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.left-container {
		width: 30%;
		min-width: 430px;
		.calendar {
			position: relative;
			width: 360px;
			padding: 20px;
			margin-top: 50px;
			border-radius: 40px;
			background: var(--white);
			box-shadow: 2px 3px 6px 0px var(--greyl);
			&:before {
				content: '';
				position: absolute;
				height: 200px;
				width: 385px;
				border-radius: 40px;
				background: var(--white);
				box-shadow: 2px 3px 6px 0px var(--greyl);
				bottom: -10px;
				left: 10px;
				z-index: -1;
			}
			.header {
				display: flex;
				justify-content: space-between;
				height: 30px;
				text-transform: uppercase;
				.arrow-cont {
					width: 45px;
					cursor: pointer;
					.arrow {
						width: 15px;
						margin: 0 15px;
						&-r {
							width: 15px;
							margin: 0 15px;
							transform: rotate(180deg);
						}
					}
				}
				p {
					font-weight: bold;
					span {
						font-weight: lighter;
					}
				}
			}
			.week {
				display: grid;
				grid-template-columns: auto auto auto auto auto auto auto;
				justify-items: center;
				align-items: center;
				.day {
					padding: 10px;
					padding-bottom: 8px;
					width: 20px;
					border-radius: 14px;
					transition: all .3s ease-in-out;
					&:hover {
						cursor: pointer;
					}
					&.active {
						box-shadow: 0px 0px 15px rgba(210, 210, 210, 0.5);
						.day-number {
							color: var(--green);
						}
					}
				}
			}
			.day {
				text-align: center;
				&:not(.in) {
					color: rgba(52, 52, 52, .5);
				}
				.day-name {
					font-size: .5rem;
					text-transform: uppercase;
					margin-bottom: 5px;
					color: rgba(52, 52, 52, .5);
					letter-spacing: .7px;
				}
				.day-number {
					font-size: 1rem;
					letter-spacing: 1.5px;
				}
				.day-event {
					min-heigth: .5rem;
					position: relative;
					margin-bottom: .5rem;
					.active-event {
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						width: .5rem;
						height: .5rem;
						border-radius: 10px;
						background-color: var(--yellow);
					}
				}
			}
		}
		.new-event {
			margin-top: 40px;
			padding: 20px;
			.add-txt {
				margin-bottom: 20px;
				span {
					color: var(--yellow);
				}
			}
			.label {
				font-size: 11px;
				cursor: pointer;
				color: var(--greyd);
				margin-bottom: 10px;
			}
		}
		.btn-section {
			display: flex;
			justify-content: flex-end;
			padding-bottom: 40px;
			.btn {
				margin: 0 0 0 20px;
			}
		}
	}
	.right-container {
		width: 50%;
		padding: 85px 0px 20px 0px;
		.card {
			width: 360px;
			padding: 15px;
		}
	}
}
</style>
