export class PublicProvider {

    constructor(companyName, adress, state) {
        this.uid = false

        this.name = companyName
        this.adress = adress
        this.state = state

        this.description = ''
        this.initialCost = 0
        this.wallpaper = false
        this.religions = []
        this.services = []
    }

    setUid(id) {
        this.uid = id
    }
    
    setWallpaper(wallpaper) {
        this.wallpaper = wallpaper
    }

    setDescription(description) {
        this.description = description
    }

    setInitialCost(initialCost) {
        this.initialCost = initialCost
    }

    setReligions(religions) {
        this.religions = religions
    }

    setServices(services) {
        this.services = services
    }
}

export const publicProviderConverter = {

    toFirestore: (publicProvider) => {
        return {
            name: publicProvider.name,
            adress: publicProvider.adress,
            state: publicProvider.state,
            religions: publicProvider.religions,
            services: publicProvider.services,

            description: '',
            initialCost: 0,
            wallpaper: '',   
        }
    },

    fromFirestore: (snapshot, options) => {
        let data = snapshot.data(options)

        let provider = new PublicProvider(data.name, data.adress, data.state)
        
        provider.setUid(snapshot.id)

        if(data.description) {
            provider.setDescription(data.description)
        }

        if(data.initialCost) {
            provider.setInitialCost(data.initialCost)
        }

        if(data.religions) {
            provider.setReligions(data.religions)
        }

        if(data.services) {
            provider.setServices(data.services)
        }

        if(data.wallpaper) {
            provider.setWallpaper(data.wallpaper)
        }

        return provider
    } 

}