<template lang="pug">
div.home-container 
    div.background
    div.search-view
        div.title
            p.t1 RENDEZ 
            p.t2 HOMMAGE
        div.search-container
            p Trouvez et comparez les pompes funèbres proches de chez vous
            div.search-bar
                div.adress.field
                    input(type="text" name="text1" id="text1" placeholder=" " :value="adress" @input="updateAdress")
                    label(for="text1") Adresse
                div.date.field
                    input(type="date" name="text2" id="text2" placeholder="JJ/MM/AAAA" :value="date" @input="updateDate")
                    label(for="text2") Date souhaitée des obsèques
                div.search-icon.onlydesk(@click="search()")
                    img(src="../../assets/Icons/search.png" alt="Search icon")
            div.religious-filter
                div.type(:class="{'choose': religions == ''}" @click="initReligions") Cérémonie civile 
                div.type(:class="{ 'choose': religions != ''}") {{ religions.length != 0 ? religions.charAt(0).toUpperCase() + religions.slice(1) : 'Cérémonie religieuse' }}
                    select(multiple size="6" @change="updateReligions").religious-cont
                        option(value="Bouddhiste") Boudhiste
                        option(value="Catholique") Catholique
                        option(value="Judaïste") Judaïste
                        option(value="Musulmane") Musulmane
                        option(value="Protestante") Protestante
                        option(value="Orthodoxe") Orthodoxe
            div.search-icon.onlyphone(@click="search()")
                p Rechercher
                img(src="../../assets/Icons/search.png" alt="Search icon")
    div.description-part
        div.description-container
            div.card-container
                div.card
                    img(src="../../assets/Img/Find.jpg" alt="Car image")
                h3 Trouvez
                p Trouvez la pompe funèbre qui saura
                p répondre à vos besoins.
            div.card-container
                div.card
                    img(src="../../assets/Img/Compare.jpg" alt="Choice image")
                h3 Consultez
                p Consultez les différentes options qui s'offrent à
                p vous pour obtenir le meilleur des services.
            div.card-container
                div.card
                    img(src="../../assets/Img/Contact.jpg" alt="Hands image")
                h3 Prenez contact
                p Prenez contact avec ce qui vous correspond
                p réellement.
</template>

<script>
export default {
    name: 'HomePage',
    data() {
        return {
            selected: '',
        }
    },

    computed: {
        adress() {
            return this.$store.getters['linking/adress']
        },
        date() {
            return this.$store.getters['linking/date']
        },
        religions() {
            return this.$store.getters['linking/religions']
        }
    },

    methods: {
        updateAdress(e) {
            this.$store.commit('linking/updateAdress', e.srcElement.value)
        },
        updateDate(e) {
            this.$store.commit('linking/updateDate', e.srcElement.value)
        },
        updateReligions(e) {
            this.$store.commit('linking/updateReligions', e.srcElement.value.toLowerCase())  
        },
        initReligions() {
            this.$store.commit('linking/updateReligions', [])  
        },
        search() {
            console.log(this.adress, this.date)
            if(this.adress != '' && this.date != '') {
                this.$router.push('/offers/benchmark')
            }
        }
    }
    
}
</script>


<style lang="scss" scoped>
.home-container {
    .background {
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 0;
        z-index: -1;
        background: var(--black);
        background-image: url('../../assets/Img/FlowerBackground.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: 100%;
    }
    .search-view {
        height: calc(100vh - 70px);
        color: var(--white);
        .title {
            padding-left: 15%;
            padding-top: 12vh;
            p {
                font-family: 'Cormo-M';
                font-size: 8em;
                &.t1 {
                    margin-bottom: -0.38em;
                }
                &.t2 {
                    margin-left: 0.5em;
                }
            }
        }
        .search-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 5vh;
            p {
                letter-spacing: 1px;
                font-size: 15px;
                box-shadow: 2px 3px 6px 0px black;
            }
        }
    }
    .description-part {
        display: flex;
        justify-content: center;
        align-items: center;
        .description-container {
            max-width: 1600px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .card-container {
                height: 400px;
                width: 400px;
                padding: 30px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .card {
                    border-radius: 10px;
                    height: 250px;
                    width: 400px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    box-shadow: 2px 3px 6px 0px #d2d2d2;
                    cursor: initial;
                    img {
                        width: 100%;
                    }
                }
                h3 {
                    font-size: var(--tmedium);
                    margin: 25px 0 5px 0;
                }
                p {
                    color: var(--greyd);
                    font-size: var(--fxsmall);
                    letter-spacing: 1px;
                    line-height: 17px;
                }
            }
        }
    }
    @media screen and (max-width: 850px), screen and (max-height: 600px) {
        .search-icon {
            margin-top: 10%;
        }
        .background {
            background-size: cover;
            background-position-x: 20%;
        }
        .search-view {
            .title {
                p {
                    font-size: 3em;
                }
            }
            .search-container {
                p {
                    letter-spacing: 1px;
                    font-size: 14px;
                    box-shadow: none;
                    text-align: center;
                    padding: 0 10px;
                }
            }
        }
        .description-part {
            .description-container {
                .card-container {
                    .card {
                        width: calc(100% - 20px);
                        border-radius: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
</style>