import { createApp } from 'vue'
import { store } from './stores/main'
import { router } from './routes/main'
import App from './App.vue'
import { Auth } from './firebase/Auth'

let app = null

Auth.onAuthStateChanged(user => {

    if (user) {
        store.commit('auth/userLogin', user)
        store.dispatch('provider/fetchProvider', user.uid)
    }

    if(app === null) {
        app = createApp(App)
        app.use(store)
        app.use(router)
    
        app.mount('#app')
    }

})