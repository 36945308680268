import { Functions } from './../firebase/Functions'
import { Firestore } from './../firebase/Firestore'
import { publicProviderConverter } from './models/PublicProvider'

export default {

    namespaced: true,

    state: {

        status: 'STOPPED',
        error: false,
        results: {}

    },
    mutations: {

        initSearch(state) {
            state.status = 'INIT'
            state.results = {}
        },

        searchDone(state) {
            state.status = 'DONE'
        },

        searchError(state, error) {
            state.status = 'STOPPED'
            state.error = error
        },

        setResult(state, row) {
            state.results = {
                ...state.results,
                [row.id]: row.data()
            }
        },

        updateResultRow(state, payload) {
            if(state.results[payload.id]) {
                let result = {
                    ...state.results[payload.id],
                    [payload.key]: payload.value
                }

                state.results = {
                    ...state.results,
                    [payload.id]: result
                }
            }
        }


    },
    actions: {

        search({ commit, dispatch }, payload) {
            let date = payload.date
            let adress = payload.adress
            let religion = payload.religion

            commit('initSearch')

            Functions.httpsCallable('searchProviders')({
                adress: adress,
                date: date,
                religion: religion
            }).then(result => {
                if(result.data) {
                    result.data.forEach(providerId => {
                        dispatch('fetchProvider', providerId)
                    })
                    commit('searchDone')
                }
            }).catch(err => {
                console.error(err)
                commit('searchError', err)
            })
        },

        fetchProvider({ commit, dispatch }, providerId) {
            Firestore.collection('providers')
                .withConverter(publicProviderConverter)
                .doc(providerId)
                .get()
                .then(doc => {
                    if(doc.data()) {
                        commit('setResult', doc)
                        dispatch('fetchImages', doc.id)
                    }
                })
        },

        fetchImages({ commit }, id) {
            Firestore.collection('providers')
                .doc(id)
                .collection('images')
                .get()
                .then(snapshot => {
                    let images = {}
                    snapshot.forEach(doc => {
                        images[doc.id] = doc.data()
                    })
                    commit('updateResultRow', {
                        id: id,
                        key: 'images',
                        value: images
                    })
                })
        },

    },
    getters: {

        results: state => state.results,
        provider: state => id => state.results[id]

    }

}