import { createStore } from 'vuex'

import auth from './AuthStore'
import provider from './ProviderStore'
import benchmark from './BenchmarkStore'
import linking from './LinkingStore'
import meeting from './MeetingStore'
import admin from './AdminStore'

export const store = createStore({

    modules: {
        auth,
        provider,
        benchmark,
        linking,
        meeting,
        admin
    }

})

