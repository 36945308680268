<template lang="pug">
div.benchmark-container
    div.sub-header
        div.title-container
            h1 Résultats de vôtre recherche
            p Pompes funèbres trouvés autour de chez vous
        div.search-container
            div.search.search-bar
                div.adress.field
                    input(type="text" name="text1" id="text1" placeholder=" " :value="adress" @input="updateAdress")
                    label(for="text1") Adresse
                div.date.field
                    input(type="date" name="text2" id="text2" placeholder="JJ/MM/AAAA" :value="date" @input="updateDate")
                    label(for="text2") Date souhaitée des obsèques
                div.search-icon(@click="search()")
                    p.onlyphone Rechercher
                    img(src="../../assets/Icons/search.png" alt="Search icon")
    div.results
        benchmark-card.provider(v-for="provider in results" :provider="provider")
            
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    data() {
        return {
            resultNumber: null,
        }
    },

    components: {
        BenchmarkCard: defineAsyncComponent(() => import('./../../components/offer/BenchmarkCard'))
    },

    computed: {
        results() {
            return this.$store.getters["benchmark/results"];
        },
        adress() {
            return this.$store.getters['linking/adress']
        },
        date() {
            return this.$store.getters['linking/date']
        },
        religion() {
            return this.$store.getters['linking/religions']
        }
    },

    methods: {
        updateAdress(e) {
            this.$store.commit('linking/updateAdress', e.srcElement.value)
        },
        updateDate(e) {
            this.$store.commit('linking/updateDate', e.srcElement.value)
        },
        search() {
            this.$store.dispatch("benchmark/search", {
                adress: this.adress,
                date: this.date,
                religion: this.religion
            })
            this.$store.commit('linking/updateAdress', this.adress)
            this.$store.commit('linking/updateDate', this.date)
        }
    },

    mounted() {
        if(this.adress != '' && this.date != '') {
            this.search()
        }
    }
};
</script>

<style lang="scss" scoped>
.benchmark-container {
    min-height: calc(100vh - 270px);
    background-image: url('./../../assets/Img/sliderB.png');
    background-repeat: no-repeat;
    background-position-x: 75%;
    padding: 30px 30px 30px 30px;
    .sub-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .title-container {
            margin-top: 20px;
            h1 {
                font-size: var(--tmedium);
                font-weight: bold;
            }
            p {
                margin-top: 10px;
                font-size: var(--fsmall);
                color: var(--greyd);
            }

        }
        .search-container {
            margin-top: 20px;
            display: flex;
            align-items: flex-end;
            .search-bar {
                box-shadow: 2px 3px 6px 0px var(--greyl);
                margin: 0;
            }
        }
    }
    .results {
        display: inline-flex;
        flex-wrap: wrap;
        margin-top: 40px;
        max-width: 1600px;
    }
    @media screen and (max-width: 850px), screen and (max-height: 600px) {
        .search-container {
            width: 100%;
        }
        .results {
            justify-content: center;
            width: 100%;
        }
    }
}
</style>