<template lang="pug">
div.signin-container
    div.card-container(v-if="isConditionAccepted")
        div.side-container.left
            h1.title--center Créez votre compte
            h2 Vous avez déjà un compte ? 
                |
                router-link(to="/providers/login") Connectez vous
            div
                //- h3 Compte contact
                div.field
                    input(type="email" name="email" id="email" placeholder=" " v-model="email")
                    label(for="email") Adresse e-mail
                div.field.password
                    input(type="password" name="password" id="password" placeholder=" " v-model="password")
                    label(for="password") Mot de passe
                    div.circle(v-bind:class="{ 'valide': password.length >= 6}")
                div.field.password
                    input(type="password" name="passwordValidation" id="passwordValidation" placeholder=" " v-model="passwordValidation")
                    label(for="passwordValidation") Validation mot de passe
                    div.circle(v-bind:class="{ 'valide': passwordValidation == password && passwordValidation != '' && passwordValidation.length >= 6}")
        div.side-container.right
            h1.subtitle Mon entreprise
            div.field
                input(type="text" name="text1" id="text1" placeholder=" " v-model="providerName")
                label(for="text1") Nom de l'entreprise
            div.field
                input(type="text" name="text2" id="text2" placeholder="1 Avenue des Champs Elysees, 75008, Paris" v-model="providerAdress")
                label(for="text2") Adresse
            div.field
                input(type="text" name="text3" id="text3" placeholder="France" v-model="providerState")
                label(for="text3") Pays/region

            div.actions
                button.btn.btn-validate(@click="submit()" v-bind:class="{ 'disabled': email == ''|| password == '' || password.length < 6 || providerName == '' || providerAdress == '' || providerState == '' || password != passwordValidation}" :disabled="email == '' || password == '' || providerName == '' || providerAdress == '' || providerState == ''") Continuer
    div.card-container(v-else)
        div.side-container.condition 
            h1 Conditions générales
            div.condition-container
                terms
            div.condition-btn
                button.btn.btn-validate(@click="isConditionAccepted = true") Accepter
                router-link(to="/providers")
                    button.btn.btn-validate.disabled Refuser
</template>

<script>
import Terms from './../../components/legal/TermsAndConditions'

export default {
    
    data() {
        return {
            email: '',
            password: '',
            passwordValidation: '',
            providerName: '',
            providerAdress: '',
            providerState: '',
            isConditionAccepted: false
        }
    },

    components: {
        Terms
    },

    methods: {

        submit() {
            this.$store.dispatch('auth/createAccount', {email: this.email, password: this.password})
                .then(credentials => {
                    let userId = credentials.user.uid
                    this.$store.dispatch('provider/create', {userId: userId, name: this.providerName, adress: this.providerAdress, state: this.providerState})
                    this.$router.push('/providers/profile')
                })
        }

    },

    mounted() {
        if(this.$store.getters['auth/isLoggedIn']) {
            this.$router.push('/providers/profile')
        }
    }

}
</script>

<style lang="scss" scoped>
.signin-container {
    min-height: calc(100vh - 270px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--black);
    background-image: url('./../../assets/Img/slider.png');
    background-repeat:  no-repeat;
    background-position-x: 75%;
    .card-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        .side-container {
            // border: 1px solid green;s
            width: 400px;
            height: 300px;
            padding: 90px 60px 60px 60px;
            background: var(--white);
            @media screen and (max-width: 650px), screen and (max-height: 600px) {
                padding: 90px 30px 60px 30px;
            }
            &.left {
                h2 {
                    margin-top: 30px;
                }
            }
            &.right {
                .subtitle {
                    margin-top: 25px;
                    font-size: 16px;
                }
            }
            h1 {
                font-size: var(--fbig);
                font-family: 'Montserrat-M';
                &.title--center {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 40px;
                    white-space: nowrap;
                }
            }
            .subtitle {
                margin: 0 0 20px 0;
            }
            h2 {
                margin: 0 0 20px 0;
                font-family: 'Montserrat-M';
                font-size: var(--fxsmall);
                a {
                    color: var(--green);
                    text-decoration: none;
                }
            }
            .actions {
                display: flex;
                justify-content: flex-end;
            }
            .password {
                position: relative;
                &::after {
                        content: 'Au moins 6 charactères';
                        position: absolute;
                        left: 0px;
                        bottom: -10px;
                        color: var(--greyd);
                        font-size: var(--fxsmall);
                        opacity: .4;
                    }
                .circle {
                    position: absolute;
                    height: 10px;
                    width: 10px;
                    border-radius: 40px;
                    top: 0px;
                    right: 5px;
                    background: var(--red);
                    transition: .25s;
                    &.valide {
                        transition: .25s;
                        background: var(--green);
                    }
                }
            }
            &.condition {
                width: 500px;
                .condition-container {
                    height: 250px;
                    overflow-y: scroll;
                }
                .condition-btn {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                    button {
                        margin: 10px;
                    }
                }
            }
        }
    }
}
</style>