import { Firestore } from './../firebase/Firestore'
import { Storage } from './../firebase/Storage'
import { providerConverter, Provider } from './models/Provider'

let collectionName = 'providers'

export default {

    namespaced: true,

    state() {
        return {
            provider: {}
        }
    },

    mutations: {

        providerAdded() { },

        providerUpdated(state, payload) {
            let provider = state.provider

            Object.keys(payload).forEach(key => {
                provider[key] = payload[key]
            })

            state.provider = {
                ...provider
            }
        },

        setProvider(state, docRef) {
            let data = docRef.data()
            state.provider = { ...data }
        }

    },

    actions: {

        create({ commit }, payload) {
            Firestore.collection(collectionName).doc(payload.userId)
                .withConverter(providerConverter)
                .set(new Provider(payload.name, payload.adress, payload.state))
                .then(() => {
                    commit('providerAdded')
                })
        },

        updateProvider({ commit }, payload) {
            Firestore.collection(collectionName).doc(payload.id)
                .set(payload.data, { merge: true })
            commit('providerUpdated', payload.data)
        },

        updateProviderServices({ commit }, payload) {
            Firestore.collection(collectionName).doc(payload.id)
                .set({
                    services: payload.data
                }, { merge: true })
            commit('providerUpdated', {
                services: payload.data
            })
        },

        updateProviderReligions({ commit }, payload) {
            Firestore.collection(collectionName).doc(payload.id)
                .set({
                    religions: payload.data
                }, { merge: true })
                commit('providerUpdated', {
                    religions: payload.data
                })
        },

        updateWallpaper({ dispatch }, payload) {
            let name = payload.id + '/wallpaper_' + payload.file.name
            let metadata = {
                contentType: payload.file.type
            }
            Storage.ref().child(name).put(payload.file, metadata)
                .then(() => {
                    dispatch('updateProvider', {
                        id: payload.id,
                        data: {
                            wallpaper: name
                        }
                    })
                })
        },

        addImage({ dispatch }, payload) {
            let name = payload.id + '/images_' + Date.now() + '_' + payload.file.name
            let metadata = {
                contentType: payload.file.type
            }
            Storage.ref().child(name).put(payload.file, metadata)
                .then(() => {
                    Firestore.collection(collectionName).doc(payload.id).collection('images').add({ url: name })
                    dispatch('fetchProvider', payload.id)
                })
        },

        removeImage({ dispatch }, payload) {
            Firestore.collection(collectionName)
                .doc(payload.id)
                .collection('images')
                .doc(payload.imageKey)
                .delete()
                .then(() => {
                    Storage.ref().child(payload.url).delete()
                    dispatch('fetchProvider', payload.id)
                })
        },

        fetchProvider({ commit, dispatch }, id) {
            Firestore.collection(collectionName)
                .withConverter(providerConverter)
                .doc(id)
                .get()
                .then(doc => {
                    if (doc.data()) {
                        commit('setProvider', doc)
                        dispatch('fetchImages', id)
                    }
                })
        },

        fetchImages({ commit }, id) {
            Firestore.collection(collectionName)
                .doc(id)
                .collection('images')
                .get()
                .then(snapshot => {
                    let images = {}
                    snapshot.forEach(doc => {
                        images[doc.id] = doc.data()
                    })
                    commit('providerUpdated', {
                        images: images
                    })
                })
        },

        setDocument({ dispatch }, payload) {
            let name = payload.providerId + '/' + payload.type + '_' + payload.file.name
            let metadata = {
                contentType: payload.file.type
            }

            Storage.ref().child(name).put(payload.file, metadata)
            dispatch('updateProvider', {
                id: payload.providerId,
                data: {
                    [payload.type]: name
                }
            })
        }

    },

    getters: {

        provider: state => state.provider,

    }

}