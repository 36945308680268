export class Provider {

    constructor(companyName, adress, state) {
        this.name = companyName
        this.adress = adress
        this.state = state

        this.isVerified = false
        this.KBIS = false
        this.description = ''
        this.initialCost = 0
        this.wallpaper = false
        this.maxCapacity = 0
        this.religions = []
        this.services = []
        this.KM = 150
        this.uid = null
    }

    setUid(id) {
        this.uid = id
    }
    
    setIsVerified(isVerified) {
        this.isVerified = isVerified
    } 

    setKBIS(kbis) {
        this.KBIS = kbis
    }

    setWallpaper(wallpaper) {
        this.wallpaper = wallpaper
    }

    setDescription(description) {
        this.description = description
    }

    setInitialCost(initialCost) {
        this.initialCost = initialCost
    }

    setMaxCapacity(maxCapacity) {
        this.maxCapacity = maxCapacity
    }

    setReligions(religions) {
        this.religions = religions
    }

    setServices(services) {
        this.services = services
    }

    setKM(km) {
        this.KM = km
    }
}

export const providerConverter = {

    toFirestore: (provider) => {
        return {
            name: provider.name,
            adress: provider.adress,
            state: provider.state,
            isVerified: provider.isVerified,
            religions: provider.religions,
            services: provider.services,
            KM: provider.KM,

            KBIS: false,
            description: '',
            initialCost: 0,
            wallpaper: '',   
            maxCapacity: 0      
        }
    },

    fromFirestore: (snapshot, options) => {
        let data = snapshot.data(options)

        let provider = new Provider(data.name, data.adress, data.state)
        
        provider.setUid(snapshot.id)

        if(data.isVerified != undefined) {
            provider.setIsVerified(data.isVerified)
        }

        if(data.description) {
            provider.setDescription(data.description)
        }

        if(data.initialCost) {
            provider.setInitialCost(data.initialCost)
        }

        if(data.wallpaper) {
            provider.setWallpaper(data.wallpaper)
        }

        if(data.KBIS) {
            provider.setKBIS(data.KBIS)
        }

        if(data.maxCapacity) {
            provider.setMaxCapacity(data.maxCapacity)
        }

        if(data.religions) {
            provider.setReligions(data.religions)
        }

        if(data.services) {
            provider.setServices(data.services)
        }

        if(data.KM) {
            provider.setKM(data.KM)
        }

        return provider
    } 

}