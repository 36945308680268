<template lang="pug">
div
	header(:class="[currentPage == '/' || currentPage == '/offers' ? transparentHeader : '']")
		div.side-header.left
			router-link(to="/")
				img(src="./assets/logoW.png" alt="Logo tree")
			div.title 
				router-link(to="/")
					h2.t1 pompes funèbres
					h2.t2 compare
		div.side-header.right
			router-link(to="/providers" v-if="loged.getters['auth/isLoggedIn'] != true")
				p Devenir vendeur
			router-link(to="/providers/profile/description" v-else)
				p.mail {{ user.email }}
			router-link(to="/providers/login" v-if="loged.getters['auth/isLoggedIn'] != true")
				img(src="./assets/Icons/UserW.png", alt="Profile icon")
			router-link(to="/providers/profile/description" v-else)
				img(src="./assets/Icons/UserW.png", alt="Profile icon")
	div.main-wrapper
		RouterView
	footer
		div.line
		div.text-container
			div.side-container.left
				h2 Contact
				a(href='pcomparateurpompesfunebres@gmail.com') comparateurpompesfunebres@gmail.com
				p.copy.onlydesk © 2021 pompes funèbres compare. Tous droits réservés
			div.side-container.right
				h2 Autre
				div
					router-link(to="/providers" v-if="loged.getters['auth/isLoggedIn'] != true")
						p Devenir vendeur
					router-link(to="/legalnotice")
						p Mentions légales
					router-link(to="/termsandconditions" v-if="loged.getters['auth/isLoggedIn'] == true")
						p Conditions générales
				p.copy.onlyphone © 2021 pompe funèbres compare. Tous droits réservés
</template>

<script>
import { store } from './stores/main'

export default {
	name: 'App',
	components: {
		store: store,
	},
	data() {
        return {
			transparentHeader: 'transparent-header',
			loged: store
        }
	},
	mounted() {
		
	},
	computed: {
        currentPage() {
            return this.$route.path
		},
        user() {
            return this.$store.getters['auth/user']
        }
    }
}
</script>

<style lang="scss">
@import './Reset.scss';
@import './Style.scss';
#app {
	min-height: 100vh;
	header {
		background: var(--dark);
		height: 70px;
		display: flex;
		justify-content: space-between;
		color: var(--white);
		&.transparent-header {
			background: transparent;
			transition: .5s;
		}
		.side-header {
			width: 300px;
			display: flex;
			align-items: center;
			.title {
				cursor: pointer;
				h2 {
					padding-left: 10px;
					&.t1 {
						padding-bottom: 0px;
					}
				}
			}
			&.left {
				img {
					width: 46px;
					margin: -12px 0 0 20px;
				}
			}
			&.right {
				justify-content: flex-end;
				padding-right: 40px;
				p {
					font-size: 14px;
					cursor: pointer;
					padding: 10px 0 10px 5px;
					&:hover {
                        color: var(--yellow);
						transition: 0.25s;
					}
					&.disapear {
						color: red;
						visibility: hidden;
						pointer-events: none;
					}
				}
				img {
					margin-left: 20px;
					height: 20px;
				}
			}
		}
	}
	.main-wrapper {
		min-height: calc(100vh - 270px);
	}
	footer {
		background: var(--dark);
		color: var(--gwhite);
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.line {
			height: 1px;
			width: 30%;
			background: var(--gwhite);
		}
		.text-container {
			display: flex;
			width: 70%;
			min-width: 700px;
			margin-top: 20px;
			margin-left: 20%;
			.side-container {
				width: 50%;
				font-size: var(--fsmall);
				h2 {
					font-size: var(--tmedium);
					color: var(--white);
				}
				a {
					color: var(--gwhite);
					font-weight: bold;
					text-decoration: none;
					&:hover {
						cursor: pointer;
						color: var(--white);
						transition: 0.25s;
					}
				}
				.copy {
					margin-top: 40px;
					font-size: var(--fxsmall);
				}
				&.right {
					margin-top: 15px;
					div {
						display: flex;
						flex-wrap: wrap;
						p {
							padding-right: 30px;
							font-size: var(--fxsmall);
							margin-bottom: 10px;
							&:hover {
								cursor: pointer;
								color: var(--white);
								transition: 0.25s;
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 850px), screen and (max-height: 600px) {
		header {
			.left {
				width: 500px;
			}
		}
		footer {
			height: 280px;
			.text-container {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				min-width: 300px;
				margin-top: 20px;
				margin-left: 0;
				.side-container {
					width: 100%;
					min-width: 300px;
					font-size: var(--fsmall);
					.copy {
						margin-top: 40px;
						padding-bottom: 10px;
						text-align: center;
						opacity: .7;
						font-size: var(--fxsmall);
					}
				}
			}
		}
	}
	@media screen and (max-width: 650px), screen and (max-height: 600px) {
		header {
			.right {
				display: none;
				.mail {
					// display: none;
				}
			}
		}
	}
}
</style>
