<template lang="pug">
div.manage-provider-description
    p.title Informations
    div.input-file-container
        div.input-file
            p.label PHOTO PRINCIPALE
            div.picture
                img(:src="wallpaperUrl")
            div.file-cont
                label.label-file(for="file") Choisir un fichier
                input.input-file(type="file" name="file" id="file" @change="uploadWallpaper" accept="image/jpeg, image/png")
    div.field
        textarea(@change="updateDescription" v-model="description")
        p.label DESCRIPTION DES SERVICES
    br
    p.label SERVICE PRIS EN CHARGE
    label.checkbox.label
        input.checkbox__input(type='checkbox' value="enterrement" v-model="services")
        svg.checkbox__check(width='18' height='18')
            polyline(points='16 4 7 14 2 10')
        p Enterrement
    label.checkbox.label
        input.checkbox__input(type='checkbox' value="cremation" v-model="services")
        svg.checkbox__check(width='18' height='18')
            polyline(points='16 4 7 14 2 10')
        p Crémation
    label.checkbox.label
        input.checkbox__input(type='checkbox' value="transport rapatriement" v-model="services")
        svg.checkbox__check(width='18' height='18')
            polyline(points='16 4 7 14 2 10')
        p Transport de corps / rapatriement 
    br
    br
    p.label SPECIFICITE DES ENTERREMENT RELIGIEUX
    label.checkbox.label
        input.checkbox__input(type='checkbox' value="bouddhiste" v-model="religions")
        svg.checkbox__check(width='18' height='18')
            polyline(points='16 4 7 14 2 10')
        p Bouddhiste
    label.checkbox.label
        input.checkbox__input(type='checkbox' value="catholique" v-model="religions")
        svg.checkbox__check(width='18' height='18')
            polyline(points='16 4 7 14 2 10')
        p Catholique
    label.checkbox.label
        input.checkbox__input(type='checkbox' value="judaïste" v-model="religions")
        svg.checkbox__check(width='18' height='18')
            polyline(points='16 4 7 14 2 10')
        p Judaïste
    label.checkbox.label
        input.checkbox__input(type='checkbox' value="musulmane" v-model="religions")
        svg.checkbox__check(width='18' height='18')
            polyline(points='16 4 7 14 2 10')
        p Musulmane
    label.checkbox.label
        input.checkbox__input(type='checkbox' value="protestante" v-model="religions")
        svg.checkbox__check(width='18' height='18')
            polyline(points='16 4 7 14 2 10')
        p Protestante
    label.checkbox.label
        input.checkbox__input(type='checkbox' value="orthodoxe" v-model="religions")
        svg.checkbox__check(width='18' height='18')
            polyline(points='16 4 7 14 2 10')
        p Orthodoxe
    br
    br
    div.field
        input(type="number" name="number3" id="number3" v-model="KM")
        label(for="number3") RAYON D'ACTIVITE (en km)
    br

    div.field
        input(type="number" name="number1" id="number1" placeholder=" " @change="updateInitialCost" :value="initialCost")
        label(for="number1") PRIX MOYEN EN EUROS
    div.field
        input(type="number" name="number2" id="number2" placeholder=" " @change="updateMaximumCapacity" :value="maximumCapacity")
        label(for="number2") CAPACITÉ DE CONTRATS PAR JOUR

    div.other-pic
        p.label PHOTOS SUPPLEMENTAIRES
        div.file-cont
            label.label-file(for="file2") Choisir un fichier
            input.input-file(type="file" name="file2" id="file2" @change="uploadImages" accept="image/jpeg, image/png")
        div.picture-container
            div.picture(v-for="(image, key) in completeImages")
                img(:src="image")
                p(@click="deleteImage(key)") +
    div.spacing
</template>

<script>
import { Storage } from './../../firebase/Storage'

export default {

    computed: {

        provider() {
            return this.$store.getters['provider/provider']
        },

        initialCost() {
            return this.provider.initialCost | 0
        },

        description() {
            return this.provider.description
        },

        maximumCapacity() {
            return this.provider.maxCapacity
        },

        user() {
            return this.$store.getters['auth/user']
        },

        images() {
            return this.provider.images
        },

        isVerified() {
            let provider = this.$store.getters['provider/provider']
            if (!provider) {
                return false
            }

            return provider.isVerified
        },

    },

    watch: {

        isVerified(val) {
            if(val === false) {
                this.$router.push('/providers/profile/check-account')
            }
        },


        services(value) {
            this.$store.dispatch('provider/updateProviderServices', {
                id: this.user.uid,
                data: value
            })
        },
        religions(value) {
            this.$store.dispatch('provider/updateProviderReligions', {
                id: this.user.uid,
                data: value
            })
        },
        KM(value) {
            this.$store.dispatch('provider/updateProvider', {
                id: this.user.uid,
                data: {
                    KM: value
                }
            })
        },
        images(value) {
            this.completeImages = {}
            if (value) {
                Object.keys(value).forEach(key => {
                    let img = value[key]
                    Storage.ref(img.url).getDownloadURL()
                        .then(url => {
                            this.completeImages[key] = url
                        })
                })
            }

        },

        provider(value) {
            this.KM = value.KM
            this.services = value.services
            this.religions = value.religions
        } 
    },

    data() {
        return {
            wallpaperUrl: '',
            completeImages: {},
            services: [],
            religions: [],
            KM: 150
        }
    },

    methods: {

        deleteImage(key) {
            this.$store.dispatch('provider/removeImage', {
                id: this.user.uid,
                imageKey: key,
                url: this.images[key].url
            })
        },

        uploadWallpaper(e) {
            let file = e.srcElement.files[0]
            if (file) {
                this.$store.dispatch('provider/updateWallpaper', {
                    id: this.user.uid,
                    file: file
                })
            }
        },

        uploadImages(e) {
            let files = e.srcElement.files
            files.forEach(file => {
                if (file) {
                    this.$store.dispatch('provider/addImage', {
                        id: this.user.uid,
                        file: file
                    })
                }
            })
        },

        updateInitialCost(e) {
            this.$store.dispatch('provider/updateProvider', {
                id: this.user.uid,
                data: {
                    initialCost: e.srcElement.value
                }
            })
        },

        updateDescription(e) {
            this.$store.dispatch('provider/updateProvider', {
                id: this.user.uid,
                data: {
                    description: e.srcElement.value
                }
            })
        },

        updateMaximumCapacity(e) {
            this.$store.dispatch('provider/updateProvider', {
                id: this.user.uid,
                data: {
                    maxCapacity: e.srcElement.value
                }
            })
        },

    },

    mounted() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters['provider/provider'],
            (newValue, oldValue) => {
                if (newValue != oldValue) {
                    Storage.ref(newValue.wallpaper).getDownloadURL()
                        .then(url => {
                            this.wallpaperUrl = url
                        })
                }

            }
        )

        if(this.isVerified === false) {
            this.$router.push('/providers/profile/check-account')
        }
    },

    beforeUnmount() {
        this.unwatch()
    },

}
</script>

<style lang="scss" scoped>
.manage-provider-description {
    .title {
        margin-bottom: 20px;
    }
    .label {
        font-size: 11px;
        cursor: pointer;
        color: var(--greyd);
        margin-bottom: 10px;
    }
    .input-file-container {
        display: flex;
        margin-bottom: 30px;
        .input-file {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
    .picture {
        height: 120px;
        width: 203px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 10px;
        background: var(--greyl);
        img {
            width: 100%;
            height: auto;
        }
    }
    .other-pic {
        .picture-container {
            display: flex;
            flex-wrap: wrap;
            .picture {
                position: relative;
                margin: 20px 20px 0 0;
                p {
                    cursor: pointer;
                    position: absolute;
                    font-size: 27px;
                    top: 5px;
                    right: 5px;
                    transform: rotate(45deg);
                }
            }
        }
    }
}
</style>