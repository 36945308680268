<template lang="pug">
div.provider-home
    h1 Prenez part au <span>1</span>er comparateur recensant
    h1 les pompes funèbres de France
    img(src="../../assets/Img/Leaf.png", alt="Leaf icon")
    p.point Améliorez votre visibilité
    p.point Gagnez en clientèle
    p.point Augmentez votre chiffre d'affaire
    div.sign-container 
        router-link(to="/providers/signin")
            button.btn.btn-validate S'inscrire

</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.provider-home {
    min-height: calc(100vh - 270px);
    background: var(--black);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-image: url('./../../assets/Img/slider.png');
    background-repeat:  no-repeat;
    background-position-x: 75%;
    h1 {
        font-size: var(--tbig);
        span {
            font-family: Georgia, 'Times New Roman', Times, serif;
            font-weight: lighter;
        }
    }
    img {
        width: 120px;
        margin: 45px 0;
    }
    .point {
        font-size: var(--fbig);
        margin: 10px 0;
    }
    .sign-container {
        display: flex;
        align-items: center;
        margin-top: 50px;
        .price {
            margin-left: 20px;
            font-size: var(--fxsmall);
        }
    }
}
</style>