<template lang="pug">
div.admin
    p Demandes d'inscription
    div.sub-title
        p Validez ou rejetez les profils
        p des différentes entreprises

    div.card(v-bind:class="{ 'active': opened[id] === true }" @click="opened[id] = (opened[id] === undefined ? true : !opened[id])" v-for="(provider, id) in unverifiedProviders")
        p.name 
        div.desc 
            p {{ provider.info.name }}
        p.label Mail
        p {{ provider.contactAccount.email }}
        p.label KBIS
        div.picture(@click="openImg($event)")
            img(:src="KBIS[id]" alt="Kbis img" ref='url' class='hola')
            div.full-screen(ref="screenImage")
                div.close(@click="closeImg($event)") +
                img(:src="imageFull" alt="Kbis img")
        p.label Carte
        p.card-wright(v-if="provider.gotCard") Carte valide
        p.card-wrong(v-else) Carte non valide
        div.btn-section()
            button.btn.btn-validate(@click="validate(id)") Accepter
            button.btn.btn-validate.disabled Refuser


    div.spacing
</template>

<script>

import { Storage } from './../firebase/Storage'

export default {
    data() {
        return {
            opened: {},
            KBIS: {},
            imageFull: ''
        }
    },

    methods: {
        validate(id) {
            this.$store.dispatch('admin/validateAccount', id)
        },
        openImg(e) {
            e.stopPropagation()
            if(e.path[0].src) {
                this.imageFull = e.path[0].src
            } else {
                if(e.path[0].children[0].src) {
                    this.imageFull = e.path[0].children[0].src
                }
            }
            this.$refs.screenImage.classList.add('open')
        },
        closeImg(e) {
            e.stopPropagation()
            this.$refs.screenImage.classList.remove('open')

        }
    },

    computed: {
        unverifiedProviders() {
            return this.$store.getters['admin/unverifiedProviders']
        }
    },

    watch: {
        unverifiedProviders(value) {
            if (value) {
                Object.keys(value).forEach(key => {
                    let provider = value[key]
                    let img = provider.info ? provider.info.KBIS : false
                    if (img) {
                        Storage.ref(img).getDownloadURL()
                            .then(url => {
                                this.KBIS[key] = url
                            })
                    }

                })
            }
        }
    },

    mounted() {
        this.$store.dispatch('admin/getUnverifiedProfiles')
        if(this.$store.getters['admin/isAdmin'] === false) {
            this.$router.push('/providers/login')
        } 
    }
}
</script>

<style lang="scss" scoped>
.admin {
    padding: 40px;
    .sub-title {
        margin: 10px 0 40px 0;
        font-size: var(--fsmall);
        color: var(--greyd);
    }
    .label {
        margin-top: 40px;
        font-size: 11px;
        cursor: pointer;
        color: var(--greyd);
        margin-bottom: 10px;
    }
    .picture {
        height: 120px;
        width: 203px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 10px;
        background: var(--greyl);
        img {
            width: 100%;
            height: auto;
        }
    }
    .card-wright, .card-wrong {
        padding: 5px 10px;
        border-radius: 40px;
        color: var(--white);
        display: inline-block
    }
    .card-wright {
        background: var(--green);
    }
    .card-wrong {
        background: var(--red);
    }
    .full-screen {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba(0, 0, 0, .5);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 2s;
        display: none;
        img {
            width: auto;
            max-width: 70%;
            max-height: 70vh;
        }
        .close {
            position: absolute;
            top: 80px;
            right: 20px;
            color: #fff;
            font-size: var(--tbig);
            transform: rotate(45deg);
        }
        &.open {
            display: inherit;
            z-index: 9;
        }
    }
}
</style>