<template lang="pug">
div.detail-container(v-if="provider")
    div.center-container
        div.side-container.left
            p.title {{ provider.name }}
            div.img-container
                div.top-img
                    img(:src="wallpaper", alt="Provider picture")
                div.bottom-img
                    div.quadra-img(v-for="image in completeImages")
                        img(:src="image")
        div.side-container.right
            p.title.description Description
            p.content {{ provider.description }}
            p.title Localisation
            p.content {{ provider.adress }}

            p.title Religions
            p.content 
                span(v-for="religion in provider.religions ") {{ religion }}  

            p.title Services
            p.content
                span(v-for="service in provider.services ") {{ service }}  

            p.title Mise en relation
            div.get-linked
                div.field
                    input(type="text" name="text1" id="text1" placeholder=" " v-model="adress" disabled)
                    label(for="text1") Adresse du cimetiere
                div.field
                    input(type="date" name="date" id="date" placeholder=" " v-model="date" disabled)
                    label(for="date") Date souhaitée des obsèques
                div.field
                    input(type="email" name="email" id="email" placeholder=" " v-model="email")
                    label(for="email") Email
                div.field
                    input(type="tel" name="tel" id="tel" placeholder=" " v-model="tel")
                    label(for="tel") Telephone
                div.btn-container
                    button.btn.btn-continue(v-if="toggle" @click="submit(), toggle = !toggle" v-bind:class="{ 'disabled': adress == '' || date == '' || email == '' || tel == ''}" :disabled="adress == '' || date == '' || email == '' ||email == ' ' || tel == ''") Etre mis en contact
                        img(src="./../../assets/Icons/belt.png" alt="Belt icon")
                    p.send(v-else) Demande envoyé
</template>

<script>
import { Storage } from '../../firebase/Storage'
export default {

    computed: {

        provider() {
            return this.$store.getters['benchmark/provider'](this.$route.params.id)
        },

        images() {
            return this.provider ? this.provider.images : null
        },

        adress() {
            return this.$store.getters['linking/adress']
        },

        date() {
            return this.$store.getters['linking/date']
        },

    },

    watch: {
        provider(value) {
            if (value !== undefined) {
                Storage.ref(value.wallpaper)
                    .getDownloadURL()
                    .then((url) => {
                        this.wallpaper = url;
                    });
            }
        },

        images(value) {
            this.completeImages = {}
            if (value) {
                Object.keys(value).forEach(key => {
                    let img = value[key]
                    Storage.ref(img.url).getDownloadURL()
                        .then(url => {
                            this.completeImages[key] = url
                        })
                })
            }

        }
    },

    data() {
        return {
            wallpaper: '',
            email: '',
            tel: '',
            completeImages: {},
            toggle: true
        }
    },

    methods: {

        submit() {

            this.$store.commit('linking/updateMail', this.email)
            this.$store.commit('linking/updateTel', this.tel)
            this.$store.dispatch('linking/createLinking', this.$route.params.id)

        }

    },

    mounted() {
        this.$store.dispatch('benchmark/fetchProvider', this.$route.params.id)

        this.email = this.$store.getters['linking/email'] || ''
        this.tel = this.$store.getters['linking/tel'] || ''
    }

}
</script>

<style lang="scss" scoped>
.detail-container {
    min-height: calc(100vh - 270px);
    display: flex;
    justify-content: center;
    background-image: url('./../../assets/Img/sliderB.png');
    background-repeat: no-repeat;
    background-position-x: 75%;
    .center-container {
        max-width: 1600px;
        display: flex;
        margin: 40px 0;
        .side-container {
            display: flex;
            flex-direction: column;
            width: 500px;
            &.left {
                .img-container {
                    height: 600px;
                    width: 450px;
                    border-radius: 20px;
                    overflow: hidden;
                    .top-img {
                        height: 300px;
                        width: 450px;
                        background: var(--greyl);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        img {
                            width: 100%;
                        }
                    }
                    .bottom-img {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        .quadra-img {
                            width: 215px;
                            height: 140px;
                            background: var(--greyl);
                            margin-top: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            &.right {
                .description {
                    margin-top: 40px;
                }
                .content {
                    margin-bottom: 40px;
                    opacity: .8;
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        background: var(--green);
                        padding: 5px 12px;
                        border-radius: 10px;
                        margin: 0 10px 10px 0;
                    }
                }
                .get-linked {
                    .field {
                        margin-bottom: 7px;
                    }
                    .btn-container {
                        display: flex;
                        justify-content: flex-end;
                        .btn {
                            display: flex;
                            align-items: center;
                            padding: 7px 7px 7px 25px;
                            img {
                                background: var(--white);
                                height: 20px;
                                width: 20px;
                                border-radius: 30px;
                                padding: 5px;
                                margin-left: 10px;
                            }
                        }
                    }
                    .send {
                        color: var(--green);
                        margin-top: 10px;
                        animation: lappear .25s ease-out forwards;
                    }
                }
            }
            .title {
                font-weight: bold;
                margin-bottom: 20px;
            }
        }
        @media screen and (max-width: 850px), screen and (max-height: 600px) {
            flex-direction: column;
        }
        @media screen and (max-width: 650px), screen and (max-height: 600px) {
            padding: 20px;
            width: calc(100% - 40px);
            .side-container {
                width: 100%;
                &.left {
                    .img-container {
                        width: 100%;
                        .top-img {
                            width: 100%;
                            img {
                                height: 100%;
                                width: auto;
                            }
                        }
                        .bottom-img {
                            width: 100%;
                            .quadra-img {
                                width: 50%;
                                  img {
                                    height: 100%;
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>