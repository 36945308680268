export class Linking {

    constructor(adress, date, mail, tel, providerId) {
        this.adress = adress
        this.date = date
        this.mail = mail
        this.tel = tel
        this.providerId = providerId

        this.uid = null
        this.isPaid = false
    }

    setUid(id) {
        this.uid = id
    }

    setIsPaid(isPaid) {
        this.isPaid = isPaid
    } 

}

export const linkingConverter = {

    toFirestore: (linking) => {
        return {
            adress: linking.adress,
            date: (new Date(linking.date)).getTime(),
            mail: linking.mail,
            tel: linking.tel,
            providerId: linking.providerId,
            hide: true
        }
    },

    fromFirestore: (snapshot, options) => {
        let data = snapshot.data(options)

        let linking = new Linking(data.adress, new Date(data.date), data.mail, data.tel, data.providerId)
        linking.setUid(data.id)
        if(data.isPaid) {
            linking.setIsPaid(data.isPaid)
        }
        
        return linking
    }

}