<template lang="pug">
div.verif-container
    p Complétez votre profil
    div.sub-title
        p Validez la partie administrative
    div.input-file-container
        div.input-file
            p.label KBIS
            div.picture
                div.cont-pdf(v-if="extension == 'pdf'")
                    img(src="../../assets/Icons/Pdf.png")
                    p.file-name {{fileName}}
                img(v-else :src="wallpaperUrl")
            div.file-cont
                label.label-file(for="file") Choisir un fichier
                input.input-file(type="file" name="file" id="file" @change="uploadKbis" accept="image/jpeg, image/jpg, image/png, application/pdf")
    add-card
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
    data(){
        return {
            wallpaperUrl: "",
            extension: "",
            fileName: ""
        }
    },

    components: {
        AddCard: defineAsyncComponent(() => import('./AddCard.vue'))
    },

    methods: {
        uploadKbis(e) {
            let file = e.srcElement.files[0]

            if(e.target.files[0].name.split('.').pop() == "pdf") {
                this.extension = "pdf"
                this.wallpaperUrl = URL.createObjectURL(e.target.files[0])
                this.fileName = e.target.files[0].name
            } else {
                this.wallpaperUrl = URL.createObjectURL(e.target.files[0])
                this.extension = ""
            }

            if(file) {
                this.$store.dispatch('provider/setDocument', {
                    type: 'KBIS',
                    providerId: this.$store.getters['auth/user'].uid,
                    file: file
                })
            }
        }

    }

}
</script>

<style lang="scss" scoped>
.verif-container {
    .sub-title {
        margin: 10px 0 40px 0;
        font-size: var(--fsmall);
        color: var(--greyd);
    }
    .label {
        font-size: 11px;
        cursor: pointer;
        color: var(--greyd);
        margin-bottom: 10px;
    }
    .input-file-container {
        display: flex;
        margin-bottom: 30px;
        .input-file {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        .picture {
            height: 120px;
            width: 203px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 10px;
            background: var(--greyl);
            position: relative;
            &:before {
                content: '.JPG.PNG.PDF';
                font-size: var(--fsmall);
                color: var(--greyd);
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                opacity: .5;
            }
            img {
                width: 100%;
                height: auto;
                z-index: 1;
            }
            .cont-pdf {
                z-index: 2;
                img {
                    margin-top: -15px;
                }
                .file-name {
                    position: absolute;
                    text-align: center;
                    white-space: nowrap;
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: var(--fsmall);
                }
            }
        }
    }
}
</style>