import { Functions } from '../firebase/Functions'
import { Firestore } from './../firebase/Firestore'
import { linkingConverter, Linking } from './models/Linking'

let collectionName = 'linkings'

export default {

    namespaced: true,

    state: {
        adress: window.localStorage.getItem('linking-adress') || '',
        date: window.localStorage.getItem('linking-date') || '',
        mail: window.localStorage.getItem('linking-mail') || '',
        tel: window.localStorage.getItem('linking-tel') || '',
        religions: window.localStorage.getItem('linking-religions') || [],

        linkings: {}
    },
    mutations: {

        updateAdress(state, adress) {
            state.adress = adress
            window.localStorage.setItem('linking-adress', adress)
        },

        updateDate(state, date) {
            state.date = date
            window.localStorage.setItem('linking-date', date)
        },

        updateMail(state, mail) {
            state.mail = mail
            window.localStorage.setItem('linking-mail', mail)
        },

        updateTel(state, tel) {
            state.tel = tel
            window.localStorage.setItem('linking-tel', tel)
        },

        updateReligions(state, religions) {
            state.religions = religions
            window.localStorage.setItem('linking-religions', religions)
        },

        linkingAdded() { },

        retrieveLinkings(state, linkings) {
            Object.keys(linkings).forEach(k => {
                state.linkings = {
                    ...state.linkings,
                    [k]: linkings[k]
                }
            })
        },

        removeLinking(state, id) {
            delete state.linkings[id]
            state.linkings = {
                ...state.linkings
            }
        }

    },
    actions: {

        createLinking({ commit, state }, providerId) {
            if (state.adress && state.date && state.mail && state.tel) {
                Firestore.collection(collectionName)
                    .withConverter(linkingConverter)
                    .add(new Linking(state.adress, state.date, state.mail, state.tel, providerId))
                    .then(() => {
                        commit('linkingAdded')
                    })
            }
        },

        getUnpaidLinkings({ commit }) {
            Functions.httpsCallable('getUnpaidLinkings')()
                .then(result => {
                    if (result.data) {
                        commit('retrieveLinkings', result.data)
                    }
                }).catch(err => {
                    console.error(err)
                })
        },

        getPaidLinkings({ commit }) {
            Functions.httpsCallable('getPaidLinkings')()
                .then(result => {
                    if (result.data) {
                        commit('retrieveLinkings', result.data)
                    }
                }).catch(err => {
                    console.error(err)
                })
        },

        payLinking({ dispatch }, linkingId) {
            Functions.httpsCallable('payLinking')({ linkingId: linkingId })
                .then(result => {
                    if (result.data) {
                        dispatch('getPaidLinkings')
                    }
                }).catch(err => {
                    console.error(err)
                })
        },

        deleteLinking({ commit }, id) {
            Firestore.collection(collectionName)
                .doc(id)
                .delete()
            commit('removeLinking', id)
        }

    },
    getters: {

        adress: state => state.adress,
        date: state => state.date,
        email: state => state.email,
        tel: state => state.tel,
        religions: state => state.religions,

        paidLinkings: state => {
            let toReturn = {}
            Object.keys(state.linkings).forEach(k => {
                let l = state.linkings[k]
                if (l.hide == false) {
                    toReturn[k] = l
                }
            })
            return toReturn
        },

        unpaidLinkings: state => {
            let toReturn = {}
            Object.keys(state.linkings).forEach(k => {
                let l = state.linkings[k]
                if (l.hide == true) {
                    toReturn[k] = l
                }
            })
            return toReturn
        }

    }

}