<template lang="pug">
div.legal-notice-view
    h1 Mentions légales
    img(src="../../assets/Img/Leaf.png", alt="Leaf icon")
    
    h2 Plateforme
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | En vertu
        | de l&apos;article 6 de la loi n&deg; 2004-575 du 21 juin 2004 pour la
        | confiance dans l&apos;&eacute;conomie num&eacute;rique, il est pr&eacute;cis&eacute; aux
        | utilisateurs du site
    a(href='http://www.pompes-funebres-compare.fr')
    font(face='Arial, serif')
        font(size='3') www.pompes-funebres-compare.fr
    font(class="link-color")
    font(face='Arial, serif')
        font(size='3')
        u
            | / www.pompes-funebres-compare.com /
            | www.comparateur-pompes-funebres.fr /
            | www.comparateur-pompes-funebres.com
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | l&apos;identit&eacute;
        | des diff&eacute;rents intervenants dans le cadre de sa r&eacute;alisation et de
        | son suivi :
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Le
        | pr&eacute;sent site est &eacute;dit&eacute; par : ADK Cocorocchia, entreprise
        | individuelle ayant pour num&eacute;ro de SIRET CHE-197.238.439 et qui est
        | domicili&eacute;e au Avenue du casino 17, Montreux 1820
        br
        br
        br
        | Responsable
        | de la publication : Anthony Cocorocchia, joignable par t&eacute;l&eacute;phone au
        | +41764654769 et par email
    a(href='mailto:comparateurpompesfunebres@gmail.com')
    font(face='Arial, serif')
        font(size='3') comparateurpompesfunebres@gmail.com
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
        br
        | La
        | soci&eacute;t&eacute; ADK Cocorocchia met &agrave; disposition son comparateur &agrave; titre
        | gracieux pour les personnes priv&eacute;es &agrave; la recherche d&rsquo;un
        | prestataire de pompes fun&egrave;bres.
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Toute
        | utilisation commerciale des comparatifs de la soci&eacute;t&eacute; ADK
        | Cocorocchia n&eacute;cessite son accord pr&eacute;alable. Par utilisation
        | commerciale, il est entendu le fait de fournir un service de conseil,
        | ou bien utiliser comme argument de vente en se fondant sur les
        | comparatifs &eacute;tablis par la soci&eacute;t&eacute; ADK. L&rsquo;autorisation peut &ecirc;tre
        | sollicit&eacute;e par une demande &eacute;crite.
    h2 Propriété intellectuelle et contrefaçons
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | L&rsquo;entreprise
        | ADK Cocorocchia est propri&eacute;taire des droits de propri&eacute;t&eacute;
        | intellectuelle ou d&eacute;tient les droits d&rsquo;usage sur tous les &eacute;l&eacute;ments
        | accessibles sur le site, notamment : les textes, les images, les
        | graphismes, le logo, les ic&ocirc;nes,&mldr; Aucun usage, sous quelque forme
        | que ce soit n&rsquo;est autoris&eacute; hormis celui initialement pr&eacute;vu
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Toute
        | reproduction, repr&eacute;sentation, modification, publication, adaptation
        | de tout ou partie des &eacute;l&eacute;ments du site, quel que soit le moyen ou
        | le proc&eacute;d&eacute; utilis&eacute;, est interdite, sauf autorisation &eacute;crite
        | pr&eacute;alable de l&rsquo;entreprise ADK Cocorocchia.
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Toute
        | exploitation non autoris&eacute;e du site ou d&rsquo;un quelconque &eacute;l&eacute;ment
        | qu&rsquo;il contient sera consid&eacute;r&eacute;e comme constitutive d&rsquo;une
        | contrefa&ccedil;on et poursuivie conform&eacute;ment aux dispositions des
        | articles L.335-2 et suivants du Code de Propri&eacute;t&eacute; Intellectuelle.
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    br
    br
    p(style='margin-bottom: 0.11in')
    font(face='Arial, serif')
    font(size='3')
        | Les
        | informations publi&eacute;es par la soci&eacute;t&eacute; ADK Cocorocchia n&apos;ont pas
        | valeur de recommandation ou d&apos;encouragement express &agrave; la vente ou &agrave;
        | l&apos;achat de quelque produit ou service que ce soit.
    p(style='margin-bottom: 0.11in')
    br
    br
    p(style='margin-bottom: 0.11in')
    font(face='Arial, serif')
    font(size='3')
        | La
        | soci&eacute;t&eacute; ADK Cocorocchia s&apos;efforce de pr&eacute;senter des informations
        | actuelles et pr&eacute;cises sur son site mais ne promet ni ne garantit,
        | explicitement ou implicitement, leur justesse, leur fiabilit&eacute; et
        | leur exhaustivit&eacute;. La soci&eacute;t&eacute; ADK Cocorocchia se r&eacute;serve
        | explicitement le droit de modifier ou de retirer les informations
        | publi&eacute;es &agrave; tout moment.
    p(style='margin-bottom: 0.11in')
    br
    br
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    h2 Cookies et traceurs
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Le site
        | internet
    a(href='https://bahbihf.r.bj.d.sendibt4.com/tr/cl/KiFbsqjNUC2tB7mrSEGR5obzN0UlCATicgG72D3gKYf-O5b_l2i5JEwM5dZ30LvumuBJugAPfYv65k9c3X8A9Hmzb0zDLLu7S5wH8B6veBj7cX6h9gHHMYHwcX7mCYr9VzE813qpLiV-JLYzSaLdBoCq10Qb8Tmk7DSy-j3VwV9Lz8x09lUyxFdflcgIuQ1YP_qVtJzfp9wOAHq_C4mEfztbMiwIqy30bFYdBqJJ9uQi95zbA9_5WwgC5yUpA-Qu5Y6yHwH3VhDYh7DpvG5peiy5e1xVy7yXh3xSnD4Ik3aB4nlBf59PuEZq0iE1ig-G0x6Tvci6-zE0PKa0vmTYvDDOc2AidB56GMoqq6AezsRCeBGfmF4lUkO0aH0klRU')
    font(class="link-color")
        font(face='Arial, serif')
        font(size='3')
            u
            | www.pompes-funebres-compare.fr
            | / www.pompes-funebres-compare.com /
            | www.comparateur-pompes-funebres.fr /
            | www.comparateur-pompes-funebres.com
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | poss&egrave;de
        | un syst&egrave;me de mesure d&rsquo;audience.
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | En
        | application de la directive europ&eacute;enne dite &laquo;&nbsp;paquet
        | t&eacute;l&eacute;com&nbsp;&raquo;, les internautes doivent &ecirc;tre inform&eacute;s et donner
        | leur consentement&nbsp;pr&eacute;alablement &agrave; l&rsquo;insertion de traceurs
        | (plus couramment appel&eacute;s &laquo;&nbsp;cookies&nbsp;&raquo;). Les internautes
        | doivent disposer d&rsquo;une possibilit&eacute; de choisir de ne pas &ecirc;tre
        | trac&eacute;s lorsqu&rsquo;ils visitent un site ou utilisent une application.
        | Les &eacute;diteurs de sites internet ont donc l&rsquo;obligation de solliciter
        | au pr&eacute;alable le consentement des utilisateurs.
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Le refus
        | d&rsquo;installation d&rsquo;un cookie peut entra&icirc;ner l&rsquo;impossibilit&eacute;
        | d&rsquo;acc&eacute;der &agrave; certains services. L&rsquo;internaute peut toutefois
        | configurer son navigateur internet pour refuser l&rsquo;installation des
        | cookies.
    p(style='margin-bottom: 0in; line-height: 100%')
    br
    p(style='margin-bottom: 0in; line-height: 100%')
    br
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Clause
        | de non responsabilit&eacute;
    p(style='margin-bottom: 0in; line-height: 100%')
    br
    p(style='margin-bottom: 0in; line-height: 100%')
    br
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | La
        | soci&eacute;t&eacute; ADK Cocorocchia ne saurait &ecirc;tre tenue pour responsable (y
        | compris en cas d&apos;imprudence ou de n&eacute;gligence) des dommages directs
        | ou indirects pouvant r&eacute;sulter de l&apos;acc&egrave;s &agrave; des &eacute;l&eacute;ments du site
        | ou &agrave; leur utilisation (ou encore de l&apos;incapacit&eacute; d&apos;y acc&eacute;der ou de
        | les utiliser), m&ecirc;me si la soci&eacute;t&eacute; ADK Cocorocchia avait eu la
        | possibilit&eacute; d&apos;avoir connaissance de ces dommages.
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='4' style='font-size: 13pt')
        b
            | Mesure
            | d&rsquo;audience
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Les
        | cookies de mesure d&rsquo;audience sont de petits fichiers qui permettent
        | de connaitre et d&rsquo;analyser les statistiques de trafic sur le site
        | internet : les pages visit&eacute;es, le nombre de visites, le taux de
        | rebond, la provenance des visites, &mldr; Les cookies de mesure
        | d&rsquo;audience sont totalement anonymes.
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Sur ce
        | site, c&rsquo;est la solution Google Analytics qui est utilis&eacute;e pour
        | mesurer l&rsquo;audience.
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
        br
    br
    h2 Formulaire de contact et commentaires
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Pour
        | &ecirc;tre contact&eacute; par les pompes fun&egrave;bres, vous &ecirc;tes amen&eacute; &agrave; nous
        | indiquer votre adresse e-mail et num&eacute;ro de t&eacute;l&eacute;phone lorsque vous
        | remplissez notre formulaire de contact du
        | site
    a(href='https://bahbihf.r.bj.d.sendibt4.com/tr/cl/hn5wP_lj__76FWlivaOEagVH5aabDUwGnFP4esyK0U9K7zagcpjV6hFL07GTDOZRpAF8yH3e9nm5oGaIEnfeJkxTyTfC_5vuttePQa7rXGoqhae5ulLg0RnXFghe7Yn9d5e2tQ_3AeBe5YnOM1KGwaO6DhoviMBQHvhGTxpkGr-aLeKBhgiiht15rqp0_ZeBfYR1vfcJIgmlwhWrzrW6OIhXhXBONDklUw7zHhSOxd-sdsjA6u5QbkJ8jugjdq8fiZ30IFaiMrLZ1qX_FI6vdbhw4k8D8O6cMFdkZzO6K9ToeOS-VQ19xFRXJyEFrQNsSBOy5q0n-bKIFUbJVL_-cBpH1HeT0rnalLUKlxLqPUM1Y22mMQ1kazrbBh5gyoQ')
    font(class="link-color")
        font(face='Arial, serif')
        font(size='3')
            u
            | www.pompes-funebres-compare.fr
            | / www.pompes-funebres-compare.com /
            | www.comparateur-pompes-funebres.fr /
            | www.comparateur-pompes-funebres.com
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3') .
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    h2 Modération des commentaires
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Le choix
        | de validation d&rsquo;un commentaire sur le
        | site
    a(href='https://bahbihf.r.bj.d.sendibt4.com/tr/cl/0FCauNMPmE6dk9y3Zdz5JwnyAMsmj66jlvAHFbDMqD7jkNyvwJs11TrpqK96imGiKk5P0_pq6WnQFFNPxgyT_oK3MtQtU-I18BlOg3zPvrGA3cqXCyiUpip3oVWz6ab2pwfAc4R5Es22ByNY8LCfaVugeIX43T-DQk-NlrrUp0lR0eeZlF6hJgeKbNcEcg2k3YMS0941vKmnsa2HPyRgtJRFd3m7oBaNGYx_tacgErF9skga2mxMRjAGmaSKCIqc6KNwoDB9VVrO3hXUABGZy6sHhMDoQC2D_gK_-gXZeSISB7uL9WyuLj_GcDd1rtU23vRglzoWOxBpfgI3gjkS8oc5Cub7_1xOYCkLYgQ5d8t9aGbDvCg5K8DcTCHZurE')
    font(class="link-color")
        font(face='Arial, serif')
        font(size='3')
            u
            | www.pompes-funebres-compare.fr
            | / www.pompes-funebres-compare.com /
            | www.comparateur-pompes-funebres.fr /
            | www.comparateur-pompes-funebres.com
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | est
        | laiss&eacute; &agrave; l&rsquo;enti&egrave;re appr&eacute;ciation du responsable de publication.
        | Les commentaires peuvent &ecirc;tre supprim&eacute;s, modifi&eacute;s et corrig&eacute;s
        | pour une meilleure compr&eacute;hension des visiteurs (notamment pour
        | l&rsquo;orthographe).
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | L&rsquo;internaute
        | peut signer son commentaire de son nom ou pseudo.
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        | Voici
        | des exemples de cas ou un commentaire peut &ecirc;tre mod&eacute;r&eacute; ou supprim&eacute;
        | :
    p(style='margin-bottom: 0in; line-height: 100%')
    font(color='#222222')
    font(face='Arial, serif')
        font(size='3')
        br
    br
    p(style='margin-left: 0.5in; margin-bottom: 0in; background: rgba(255,255,255,0.25); line-height: 100%')
    br
    ul
    li
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
        font(color='#222222')
        font(face='Arial, serif')
            font(size='3')
            | Il a
            | &eacute;t&eacute; supprim&eacute; par l&rsquo;anti-spam
    p(style='margin-left: 0.5in; margin-bottom: 0in; background: rgba(255,255,255,0.25); line-height: 100%')
    br
    ul
    li
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
        font(color='#222222')
        font(face='Arial, serif')
            font(size='3')
            | Il
            | n&rsquo;apporte pas r&eacute;ellement de plus value et n&rsquo;est pas utile pour
            | les internautes
    p(style='margin-left: 0.5in; margin-bottom: 0in; background: rgba(255,255,255,0.25); line-height: 100%')
    br
    ul
    li
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
        font(color='#222222')
        font(face='Arial, serif')
            font(size='3')
            | Il est
            | truff&eacute; de fautes d&rsquo;orthographe ou incompr&eacute;hensible
    p(style='margin-left: 0.5in; margin-bottom: 0in; background: rgba(255,255,255,0.25); line-height: 100%')
    br
    ul
    li
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
        font(color='#222222')
        font(face='Arial, serif')
            font(size='3')
            | Il
            | semble &ecirc;tre d&eacute;pos&eacute; uniquement dans un but auto-promotionnel
    p(style='margin-left: 0.5in; margin-bottom: 0in; background: rgba(255,255,255,0.25); line-height: 100%')
    br
    ul
    li
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
        font(color='#222222')
        font(face='Arial, serif')
            font(size='3')
            | Le mail
            | indiqu&eacute; est visiblement faux
    p(style='margin-left: 0.5in; margin-bottom: 0in; background: rgba(255,255,255,0.25); line-height: 100%')
    br
    ul
    li
    p(style='margin-top: 0.19in; margin-bottom: 0.19in; background: rgba(255,255,255,0.25); line-height: 100%')
        font(color='#222222')
        font(face='Arial, serif')
            font(size='3')
            | Il est
            | jug&eacute; diffamatoire pour un tiers
    p(style='margin-left: 0.5in; margin-bottom: 0in; background: rgba(255,255,255,0.25); line-height: 100%')
    br
    p(style='margin-bottom: 0in; line-height: 100%')
    br


    br
    br
    br

</template>

<script>
import Terms from './../../components/legal/TermsAndConditions'

export default {
      components: {
        Terms
    },
}
</script>

<style lang="scss" scoped>
.legal-notice-view {
    padding: 20px 40px;
    min-height: calc(100vh - 270px);
    background: var(--black);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-image: url('./../../assets/Img/slider.png');
    background-repeat:  no-repeat;
    background-position-x: 75%;
    h1 {
        font-size: var(--tbig);
        margin-top: 40px;
    }
    h2 {
        font-size: 30px;
    }
    .link-color {
        color: var(--yellow);
    }
    img {
        width: 120px;
        margin: 45px 0;
    }
}
</style>