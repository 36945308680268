<template lang="pug">
div.manage-linking-container
    p Demandes de mise en relation
    div.sub-title
        p Validez ou rejetez les demandes de contacts
        p pour recevoir leur mail et numéro de téléphone

    div.card(v-for="(linking, id) in unpaidLinkings" v-bind:class="{ 'active': linking.toggle === 'active' }" @click="linking.toggle = linking.toggle !== 'active' ? 'active' : null")
        p.name {{ linking.adress }}
        div.desc 
            p {{ (new Date(linking.date)).toLocaleDateString(undefined) }}
        div.locked(v-if="linking.hide")
        div.unlocked(v-else)
            img(src="../../assets/Icons/Check.png", alt="Validate icon")
        div.btn-section(v-if="linking.hide")
            button.btn.btn-validate(@click="openPopup($event)") Accepter
            div.popup-validation
                div.close(@click="closePopup($event)") +
                div.popup(ref="popup")
                    h1 Mise en contact
                    p L'obtention des coordonées de ce client vous seront facturé 19,90€
                    div
                        button.btn.btn-validate(@click="$store.dispatch('linking/payLinking', id)") Accepter
                        button.btn.btn-validate.disabled(@click="closePopup($event)") Refuser
            button.btn.btn-validate.disabled(@click="$store.dispatch('linking/deleteLinking', id)") Refuser
        div.info(v-else)
            p Mail: {{ linking.mail }}
            p Tel: {{ linking.tel }}
    div.spacing

    div.card(v-for="(linking, id) in paidLinkings" v-bind:class="{ 'active': linking.toggle === 'active' }" @click="linking.toggle = linking.toggle !== 'active' ? 'active' : null")
        p.name {{ linking.adress }}
        div.desc 
            p {{ (new Date(linking.date)).toLocaleDateString(undefined) }}
        div.locked(v-if="linking.hide")
        div.unlocked(v-else)
            img(src="../../assets/Icons/Check.png", alt="Validate icon")
        div.btn-section(v-if="linking.hide")
            button.btn.btn-validate(@click="$store.dispatch('linking/payLinking', id)") Accepter
            button.btn.btn-validate.disabled(@click="$store.dispatch('linking/deleteLinking', id)") Refuser
        div.info(v-else)
            p Mail: {{ linking.mail }}
            p Tel: {{ linking.tel }}
</template>

<script>
export default {
    methods: {
        openPopup(e) {
            e.stopPropagation()
            this.$refs.popup.classList.add('open')
        },
        closePopup(e) {
            e.stopPropagation()
            this.$refs.popup.classList.remove('open')
        }
    },

    computed: {
        unpaidLinkings() {
            return this.$store.getters['linking/unpaidLinkings']
        },

        paidLinkings() {
            return this.$store.getters['linking/paidLinkings']
        },

        isVerified() {
            let provider = this.$store.getters['provider/provider']
            if (!provider) {
                return false
            }

            return provider.isVerified
        },
    },

    watch: {
        isVerified(val) {
            if (val === false) {
                this.$router.push('/providers/profile/check-account')
            }
        },
    },

    mounted() {
        this.$store.dispatch('linking/getUnpaidLinkings')
        this.$store.dispatch('linking/getPaidLinkings')

        if(this.isVerified === false) {
            this.$router.push('/providers/profile/check-account')
        }
    }
}
</script>

<style lang="scss" scoped>
.manage-linking-container {
    .sub-title {
        margin: 10px 0 40px 0;
        font-size: var(--fsmall);
        color: var(--greyd);
    }
    .popup-validation {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba(0, 0, 0, .5);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 2s;
        display: none;
        .popup {
            height: 300px;
            width: 600px;
            max-height: calc( 80vh - 40px);
            max-width: calc( 90% - 40px);
            background: var(--white);
            border-radius: 10px;
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h1 {
                font-size: var(--tbig);
            }
            p {
                text-align: center;
                margin: 10px 0;
                margin-top: 10%;
            }
            div {
                margin-top: 10%;
            }
        }
        .close {
            position: absolute;
            top: 80px;
            right: 20px;
            color: #fff;
            font-size: var(--tbig);
            transform: rotate(45deg);
        }
        &.open {
            display: inherit;
            z-index: 9;
        }
    }
}
</style>