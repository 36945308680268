import { createRouter, createWebHistory } from 'vue-router'
import { store } from './../stores/main'

import OfferHome from './../views/Offer/Home.vue'
import OfferBenchmark from './../views/Offer/Benchmark.vue'
import OfferDetail from './../views/Offer/Detail.vue'

import ProviderHome from './../views/Provider/Home.vue'
import ProviderLogin from './../views/Provider/Login.vue'
import ProviderSignin from './../views/Provider/Signin.vue'
import ProviderProfile from './../views/Provider/Profile.vue'

import TermsAndConditionsView from './../views/Legal/TermsAndConditionsView.vue'
import LegalNoticeView from './../views/Legal/LegalNoticeView.vue'

import ManageVerifiedAccount from './../components/provider/ManageVerifiedAccount.vue'
import ManageProviderDescription from './../components/provider/ManageProviderDescription.vue'
import Calendar from './../components/provider/Calendar.vue'
import ManageLinking from './../components/provider/ManageLinking.vue'
import Admin from './../views/Admin.vue'

const routes = [
	{ path: '/', component: OfferHome },
	{ path: '/offers', component: OfferHome },
	{ path: '/offers/benchmark', component: OfferBenchmark },
	{ path: '/offers/detail/:id', component: OfferDetail },
	{ path: '/termsandconditions', component: TermsAndConditionsView },
	{ path: '/legalnotice', component: LegalNoticeView },
	{ path: '/providers', component: ProviderHome },
	{ path: '/providers', component: ProviderHome },
	{ path: '/providers/login', component: ProviderLogin },
	{ path: '/providers/signin', component: ProviderSignin },
	{
		path: '/providers/profile',
		component: ProviderProfile,
		meta: { requiresAuth: true },
		children: [
			{ path: 'check-account', component: ManageVerifiedAccount },
			{ path: 'description', component: ManageProviderDescription },
			{ path: 'calendar', component: Calendar },
			{ path: 'linkings', component: ManageLinking }
		]
	},
	{ path: '/admin', component: Admin, meta: { requiresAuth: true } }
]

export const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	if (to.meta.requiresAuth === true && store.getters['auth/isLoggedIn'] !== true) {
		next(from)
	} else {
		next()
	}
})